import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiHome2Line } from 'react-icons/ri';
import"../../style/login/navbar-admi.css"
import { MdOutlineSettings } from "react-icons/md";
import { MdPowerSettingsNew } from "react-icons/md";
import { useAuth } from "../context/tokenadmin";
import { Grid, Modal, Box, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
/* imagenes */
import gruaicons from "../../img/login/grua.png"
import racks from "../../img/login/almacen.png"
import logo from "../../img/login/logo.png"

const CustomGrid = styled(Grid)(({ theme }) => ({
    maxWidth: "100%",
    display: "flex",
    width:"100%",
    height:"100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    '@media (min-width: 900px)': {
        maxWidth: "100%",
         Width: "100%"
    },
    '@media (min-width: 600px)': {
        maxWidth: "100%",
         Width: "100%"
    }
    
}



));

export const Navbaradmin = () => {
    const { token, logout } = useAuth();
    const [expandedItems, setExpandedItems] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        setOpen(true); // Abrir el modal
        await logout();
        setOpen(false); // Cerrar el modal después de la operación de cierre de sesión
    };

    const toggleExpand = (itemName) => {
        setExpandedItems(prevState => prevState === itemName ? null : itemName);
    };


    const handleItemClick = () => {
        setExpandedItems(null); // Cierra el navbar
    };


    useEffect(() => {

        // Verifica si el token no existe y redirige
        if (!token) {
            navigate('/stacker'); // o la ruta que desees
         logout(); 
         }

    }, [, token, logout, navigate]);

    return (
        <>
            <nav className={`navbar-admin ${expandedItems ? 'expanded' : ''}`}>
                <CustomGrid container >
                    <CustomGrid item xs={2} >
                        <Link to="/adminstacker"  onClick={handleItemClick}>
                        <div className="contienenlogostacker">
                            <img src={logo} className="imagenlogostacker" alt="stacker" title="stacker" width="100%" height="100%" />
                        </div>
                        </Link>
                    </CustomGrid>
                    <CustomGrid item xs={7}  >
                        <div className="contieneinfoadministacker">
                            {/* Botón de Inicio Stacker */}
                            <div className={`toggle-btn ${expandedItems === 'home' ? 'expanded' : ''}`} onClick={() => toggleExpand('home')}>
                               <div  style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row"}}> <RiHome2Line /> <span className={`item-title ${expandedItems === 'home' ? 'visible' : ''}`} >Inicio Stacker</span></div>
                            </div>

                            {/* Lista de ítems del navbar para Inicio Stacker */}
                            <ul className={`navbar-items ${expandedItems === 'home' ? 'expanded' : ''}`}>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/portadaadminhome">
                                        <span>Portada inicio</span>
                                    </Link>
                                </li>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/cardhome ">
                                        <span>Card inicio</span>
                                    </Link>
                                </li>
                                
                            </ul>
                             
                            {/* Botón de Maquinas */}
                            <div className={`toggle-btn ${expandedItems === 'maquinas' ? 'expanded' : ''}`} onClick={() => toggleExpand('maquinas')}>
                              <div  style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row"}}>  <div className="iconogruamenu">
                                    <img src={gruaicons} alt="stacker" title="stacker" height="100%" width="100%" />
                                </div>
                                <span className={`item-title ${expandedItems === 'maquinas' ? 'visible' : ''}`}>Maquinarias</span></div>
                            </div>

                            {/* Lista de ítems del navbar para Maquinas */}
                            <ul className={`navbar-items ${expandedItems === 'maquinas' ? 'expanded' : ''}`}>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/portadamaquinaria">
                                        <span>Portada</span>
                                    </Link>
                                </li>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/cardadminhome">
                                        <span>Categoría maquinaria</span>
                                    </Link>
                                </li>
                               
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/productomaqunariaadmi">
                                        <span>Maquinas</span>
                                    </Link>
                                </li>
                            </ul>

                            {/* Botón de Racks */}
                            <div className={`toggle-btn ${expandedItems === 'racks' ? 'expanded' : ''}`} onClick={() => toggleExpand('racks')}>
                               <div  style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"row"}}> <div className="iconogruamenu">
                                    <img src={racks} alt="stacker" title="stacker" height="100%" width="100%" />
                                </div>
                                <span className={`item-title ${expandedItems === 'racks' ? 'visible' : ''}`}>Racks</span></div>
                            </div>

                            {/* Lista de ítems del navbar para Racks */}
                            <ul className={`navbar-items ${expandedItems === 'racks' ? 'expanded' : ''}`}>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/portadarackadmin">
                                        <span>Portada</span>
                                    </Link>
                                </li>
                                <li className="itemlist"  onClick={handleItemClick}>
                                    <Link to="/productoracksadmi">
                                        <span>Racks</span>
                                    </Link>
                                </li>
                            </ul>

                            {/* Botón de Configuración */}
                           {/*  <div className={`toggle-btn ${expandedItems === 'configuracion' ? 'expanded' : ''}`} onClick={() => toggleExpand('configuracion')}>
                              <div  onClick={handleItemClick}>  <MdOutlineSettings /> <span className={`item-title ${expandedItems === 'configuracion' ? 'visible' : ''}`}>Configuración</span></div>
                            </div> */}
                        </div>
                    </CustomGrid>
                    <CustomGrid item xs={1} >
                        <input id="checkbox" type="checkbox" />
                        <label className="switch" htmlFor="checkbox" onClick={handleLogout}>
                            <MdPowerSettingsNew />
                        </label>
                    </CustomGrid>
                </CustomGrid>
            </nav>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <h2>Cerrando sesión</h2>
                    <CircularProgress />
                </Box>
            </Modal>
        </>
    );
};