import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../style/login/administrador.css"
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import "../../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";

import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal,
    Select,
    MenuItem 
} from "@mui/material";

export const Cardtodosadminhome = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [portadasData, setPortadasData] = useState([]);
    const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isLoading, setIsLoading] = useState(true); 
    const [selectedCategory, setSelectedCategory] = useState(''); // Estado para la categoría seleccionada
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const fetchCategoria = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/get/all/videos`, {
                headers: {
                    'Authorization': `Bearer ${token}`, 
                },
            });

            setPortadasData(response.data);  
            if (response.status === 200 && response.data.length > 0) {
                const portadaGaleriaResponse = await axios.get(`${backendURL}/api/get/all/videos?id=${response.data[0].id}`);
                setPortadasGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
            }
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } finally {
            setIsLoading(false); 
        }
    };

    useEffect(() => {
        fetchCategoria();
    }, []);

    useEffect(() => {
        if (token) {
            fetchCategoria();
        }
    }, [token]);

    useEffect(() => {
        setShowNavbarFooter(false);

        if (!token) {
            logout(); 
            navigate('/stacker'); 
        }
    }, [setShowNavbarFooter, token, logout, navigate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModals = (categoria) => {
        setPortadasGaleriaData(categoria);
        setIsModalOpens(true);
    };

    const handleCloseModals = () => {
        setIsModalOpens(false);
    };

    const handleDelete = async (categoriaId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`${backendURL}/api/deleteVideoapi/${categoriaId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                fetchCategoria();
            } else {
                console.error('Error al eliminar la Categoria:', response);
            }
        } catch (error) {
            console.error('Error al eliminar la categoria:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Manejar el cambio de categoría
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Filtrar los datos por categoría
    const filteredData = selectedCategory
        ? portadasData.filter((categoria) => categoria.tipo === selectedCategory)
        : portadasData;
    return(
         <>
      <Container maxWidth="lg">
        <Box>
            <h2 className='tituloadministrador'> Card inicio Stacker</h2>
        </Box>
        <Box >
            <button  onClick={handleOpenModal} className='buttonaccionedit'> <MdOutlineCreateNewFolder /> <span>Crear</span></button> 
       
        </Box>
        <Box>
        <div>
                        <label>Filtrar por Categoría: </label>
                        <Select
                        fullWidth
                        margin="normal"
                        name="tipo"
                        value={selectedCategory}
                         onChange={handleCategoryChange}
                    >
                        <MenuItem value="cliente">Cliente</MenuItem>
                        <MenuItem value="servicios">Servicios</MenuItem>
                        <MenuItem value="portada">Portada</MenuItem>
                    </Select>
                        
                    </div>
            
        </Box>
        <Box sx={{marginBottom:"100px"}}> 
        <TableContainer component={Paper}>
        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}// Pasando la longitud del array
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />   
                              <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Titulo</TableCell>
                                        <TableCell>categoria</TableCell>
                                        <TableCell>Link</TableCell>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Imagen</TableCell>
                                        <TableCell>Logo</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {filteredData.length > 0 ? (
                                    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((categoria) => (
                                            <TableRow key={categoria.id}>
                                                <TableCell>{categoria.titulo}</TableCell>
                                                <TableCell>{categoria.tipo}</TableCell>
                                                <TableCell>{categoria.url}</TableCell>
                                                <TableCell>{categoria.descripcion}</TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.logo}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell>
                                                <div>
                                        <button className='buttonaccionedit'  onClick={() => handleOpenModals(categoria)} > <FiEdit /> <span>Editar</span></button>
                                            <button className='buttonacciondelete'  onClick={() => handleDelete(categoria.id)}  disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                                </button>
                                        
                                        </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No hay card disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                   
                </TableContainer>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "5%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModal}><IoClose /></div>
                <Crearcardhome handleClose={handleCloseModal}  refreshPortadas={fetchCategoria} /> </Box>
                
            </Modal>

            <Modal open={isModalOpens} onClose={handleCloseModals}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "1%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModals}><IoClose /></div>
                <Editarcardhome
                 handleClose={handleCloseModals}
                   refreshPortadas={fetchCategoria} 
                selectedPortada={portadasGaleriaData}/>  </Box>
                
            </Modal>
        
        
      </Container>
    
    
    </>)
}



// Componente para crear la portada
const Crearcardhome = ( { handleClose, refreshPortadas}) => {
    const { token} = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        titulo: '',
        url:'',
        tipo:'',
        descripcion: '',
        imagen: null,
        logo:null,
        alt_seo: ''
    });

    

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen' || name === 'logo' ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.titulo) errors.titulo = 'El título es obligatorio';
        if (!formData.url) errors.url = 'El link es obligatorio';
        if (!formData.tipo) errors.tipo = 'La categoría es obligatoria';
        if (!formData.descripcion) errors.descripcion = 'La descripción es obligatoria';
        if (!formData.imagen) errors.imagen = 'La imagen es obligatoria';
        if (!formData.logo) errors.logo = 'El logo es obligatorio';
        if (!formData.alt_seo) errors.alt_seo = 'El texto SEO es obligatorio';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!validateForm()) {
            setErrorModalOpen(true);
            return;
        }

        setIsLoading(true);
    
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
        try {  const response = await axios.post(`${backendURL}/api/storeVideosapi`,formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            
            
            if (response.data.success) {
                setSuccessModalOpen(true);
                refreshPortadas(); 
            } else {
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorModalOpen(true);
            console.error('Error creating portada:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    
  
   
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Crear Nueva Card inicio</Typography>
          
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="titulo">Título</InputLabel>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="titulo"
                        value={formData.titulo}
                        onChange={handleChange}
                        error={!!formErrors.titulo}
                        helperText={formErrors.titulo}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="url">Link</InputLabel>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        error={!!formErrors.url}
                        helperText={formErrors.url}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="tipo">Categoría</InputLabel>
                    <Select
                        fullWidth
                        margin="normal"
                        name="tipo"
                        value={formData.tipo}
                        onChange={handleChange}
                        error={!!formErrors.tipo}
                    >
                        <MenuItem value="cliente">Cliente</MenuItem>
                        <MenuItem value="servicios">Servicios</MenuItem>
                        <MenuItem value="portada">Portada</MenuItem>
                    </Select>
                    {formErrors.tipo && <Typography color="error">{formErrors.tipo}</Typography>}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="descripcion">Descripción</InputLabel>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        error={!!formErrors.descripcion}
                        helperText={formErrors.descripcion}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen">Imagen</InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!formErrors.imagen}
                        helperText={formErrors.imagen}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="logo">Logo</InputLabel>
                    <TextField
                        type="file"
                        name="logo"
                        id="logo"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!formErrors.logo}
                        helperText={formErrors.logo}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="alt_seo">SEO</InputLabel>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="alt_seo"
                        value={formData.alt_seo}
                        onChange={handleChange}
                        error={!!formErrors.alt_seo}
                        helperText={formErrors.alt_seo}
                    />
                </FormControl>
                <button className='buttonaccionedit' type="submit" disabled={isLoading}>
                    {isLoading ? (<CircularProgress />) : (<> Guardar </>)}
                </button>
            </form>

            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
                <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">¡Categoria creada correctamente!</Typography>
                    <button className='buttonaccionedit' onClick={() => setSuccessModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>

            {/* Error Modal */}
            <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
                <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">Ocurrió un error. Inténtalo de nuevo.</Typography>
                    <button className='buttonaccionedit' onClick={() => setErrorModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>
        </Box>
    );
};

const Editarcardhome = ({ handleClose, refreshPortadas, selectedPortada }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        titulo: '',
        url:'',
        tipo:'',
        descripcion: '',
        imagen: null,
        logo:null,
        alt_seo: ''
    });

    
    useEffect(() => {
        if (selectedPortada) {
            setFormData({
                titulo: selectedPortada.titulo,
                url: selectedPortada.url,
                tipo: selectedPortada.tipo,
                descripcion: selectedPortada.descripcion,
                alt_seo: selectedPortada.alt_seo,
                imagen: selectedPortada.imagen,
                logo: selectedPortada.logo,
            });
        }
    }, [selectedPortada]);
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen' || name === 'logo') {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('titulo', formData.titulo);
        formDataToSend.append('url', formData.url);
        formDataToSend.append('tipo', formData.tipo);
        formDataToSend.append('descripcion', formData.descripcion);
        formDataToSend.append('alt_seo', formData.alt_seo);
        
        if (formData.imagen) {
            formDataToSend.append('imagen', formData.imagen);
        }
        if (formData.logo) {
            formDataToSend.append('logo', formData.logo);
        }

        // Verifica los datos que se están enviando
        for (let [key, value] of formDataToSend.entries()) {
            console.log(key, value);
        }

        const response = await axios.post(`${backendURL}/api/editVideosapi/${selectedPortada.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            refreshPortadas();
            handleClose();
        } else {
            console.error('Error al actualizar la portada:', response);
        }
    } catch (error) {
        console.error('Error al actualizar la portada:', error);
    } finally{
        setIsLoading(false);
        handleClose();
    }

    };
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Editar card inicio</Typography>
            {/* Campos del formulario */}
            <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Titulo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal" 
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Link
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal" 
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ marginTop:"-7px   " }}>
                       Categoria
                    </InputLabel>
                    <Select
                fullWidth
                margin="normal"
                name="tipo"
                value={formData.tipo}  // Asocia el valor seleccionado con el estado
                onChange={handleChange}
               
            >

                <MenuItem value="cliente">Cliente</MenuItem>
                <MenuItem value="servicios">Servicios</MenuItem>
                <MenuItem value="portada">Portada</MenuItem>
            </Select>
                </FormControl>
                   <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Descripción 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />
                </FormControl>
                
                  <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{
                        top:"-7px  "
                    }}>
                        Imagen 
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {formData.imagen && (
                        <img
                            src={`${backendURL}/${formData.imagen}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{
                        top:"-7px  "
                    }}>
                       logo
                    </InputLabel>
                    <TextField
                        type="file"
                        name="logo"
                        id="logo"
                        
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {formData.logo && (
                        <img
                            src={`${backendURL}/${formData.logo}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                   
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                />
                 </FormControl>
                 <button className='buttonaccionedit' type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>
        </Box>
    );
};