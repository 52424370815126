import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "../../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import { Container } from "@mui/material";
import lineas from "../../img/home/contactohome/lineas.png";

export default function PorductosHome() {
  const navigate = useNavigate();
  const [categorias, setCategorias] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    AOS.init({ duration: 500000 });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/get/all/categorias`);
        if (response.status === 200) {
          setCategorias(response.data);
        } else {
          console.error("Error al obtener datos");
        }
      } catch (error) {
        console.error("Error de red", error);
      }
    };

    fetchData();
  }, [backendURL]);

  const handleVerMasClick = (categoriaId) => {
    navigate(`/productos?categoria=${categoriaId}`);
  };

  return (
    <Container maxWidth="xl">
      <section className="titulo-nosotros-home" data-aos="fade-down">
        <img src={lineas} className="imagenlineastitulos" alt="stacker" title="stacker"   height="100%"
          width="100%"/>
        <p className="titulo-home-nosotros"  aria-hidden="true" tabIndex="-1">NUESTRAS MAQUINARIAS STACKER</p>

      
      </section>
      {categorias.length > 0 ? (
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          breakpoints={{
            240: {
              slidesPerView: 1,
              spaceBetween: 4,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            880: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 1,
            },
            1565: {
              slidesPerView: 5,
              spaceBetween: 1,
            },
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="sliderhomeportada"
        >
          {categorias.map((categoria) => (
            <SwiperSlide key={categoria.id} className="categoria-slider-home" onClick={() => handleVerMasClick(categoria.id)}>
              <div className="categoria-home" data-aos="fade-down" >
                <div className="cardcontenidocategoria">
                  <div className="imagen-categoria-producto-home">
                    {categoria.imagen && (
                      <picture>
                        <source srcSet={`${backendURL}/${categoria.imagen}`} type="image/webp" />
                        <img
                          src={`${backendURL}/${categoria.imagen}`}
                          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                            height="100%"
                            width="100%"
                        />
                      </picture>
                    )}
                  </div>
                  <div className="info-categoria-home">
                    <button aria-hidden="true" tabindex="-1" className="seobuttonhidden ">
                  <p className="titulo-contenido-categoria" aria-hidden="true" tabindex="-1">{categoria.descripcion} </p>
                  </button>
                 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Cargando categorías...</p>
      )}
    </Container>
  );
}