import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Texto1 } from "./maquinarias";
import ReactPlayer from "react-player";
import AOS from 'aos';
import 'aos/dist/aos.css'
import "../../style/home/home.css";
import axios from 'axios'; 
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import imageninfo from "../../img/home/contactohome/1.png";
import imagenvideo from "../../img/home/contactohome/2.png";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";




export default function Contacthome() {
    const [modalOpen, setModalOpen] = useState(false);
    const [videos, setVideos] = useState([]);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
      AOS.init({
        // Configura las opciones aquí
        duration: 2000,
      });
    }, []);

  const handleOpenModal = (url) => {
    setModalOpen(true);
    setSelectedVideoUrl(url);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedVideoUrl('');
  };

  useEffect(() => {
    axios.get(`${backendURL}/api/get/all/videos`)
      .then(response => {
        const portadaVideos = response.data.filter(video => video.tipo === 'portada');
        setVideos(portadaVideos);
      })
      .catch(error => {
        console.error('Error fetching videos:', error);
      });
  }, []);

  return (
    <>
    <section data-aos="fade-down" style={{marginTop:"-4px"}}>
      <Texto1/>
      <Grid container className="contenedor-home" sx={{padding:"0px"}} >
        <Grid item xs={12} sm={6} className="subcontenedor-home-info-primero">
        
        {videos.length > 1 ? (
  <Swiper
    slidesPerView={1}
    spaceBetween={1}
    modules={[Autoplay, EffectFade]}
    autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    loop={true}
    className="sliderhomeproducto"
  >  {videos.map(video => (
    <SwiperSlide key={video.id}> 
     <div  className="video-principal-home">  
              {video.imagen && (
               <div  className="contieneimagenvideohome" > 
                <picture>
                <source srcSet={`${backendURL}/${video.imagen}`} type="image/webp" />
               <img
                  src={`${backendURL}/${video.imagen}`}
                  alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  className="imagen-video-segunda-info-home"
                    height="100%"
                    width="100%"
                 
                />
                </picture>
                </div>
              )}
              <button className="button-video-home" onClick={() => handleOpenModal(video.url)} aria-hidden="true" tabIndex="-1">
                <svg
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="26px"
                >
                  <path
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
    </SwiperSlide>
    ))}
      </Swiper>
    ) : (
      videos.map(video => (
            <div key={video.id} className="video-principal-home">
              {video.imagen && (
               <div  className="contieneimagenvideohome" > 
                <picture>
                <source srcSet={`${backendURL}/${video.imagen}`} type="image/webp" />
               <img
                  src={`${backendURL}/${video.imagen}`}
                  alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  className="imagen-video-segunda-info-home"
                    height="100%"
                    width="100%"
                 
                />
                </picture>
                </div>
              )}
              <button className="button-video-home" onClick={() => handleOpenModal(video.url)} aria-hidden="true" tabIndex="-1">
                <svg
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="26px"
                >
                  <path
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
         ))
        )}
        
      </Grid>
      <Grid item xs={12} sm={6} className="subcontenedor-home-info-primero" >
        <a href="/productos" title="contacto" >
      <div className="contiene-cotizar-home">
        <div className="contieneimagencotizar">
        <picture>
        <source srcSet={imageninfo} type="image/webp" />
      <img src={imageninfo} alt="stacker" title="stacker" className="imagen-video-segunda-info-home"  height="100%"
          width="100%" />
    </picture><h2 style={{position:"relative", zIndex:"-11111", marginBottom:"0px", marginTop:"0px", width:"0px", height:"0px", color:"rgb(255 255 255 / 0%)"}}>grúa horquilla, racks, pasillo angosto, transpaleta,  Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas, apilador.</h2>
     </div>
     
      <div className='info-segundo-home'>
       <h3>Nuestros Productos</h3>
        <h4 className='subtitulodos-segundo-home' aria-hidden="true" tabIndex="-1">La máquina y la modalidad que más te acomode. grúas horquillas, transpaletas, apiladores, pasillo angosto, reach stacker, alzahombres. Tú eliges.
        </h4>
   
    
        <button className="button type1" aria-hidden="true" tabIndex="-1">
  <span className="btn-txt" aria-hidden="true" tabIndex="-1">VER MÁS</span>
</button>
      </div>
      </div></a>
      </Grid>
        </Grid>
     
          </section>   {modalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
            <ReactPlayer
             url={selectedVideoUrl}
             controls={true} 
              className="video-cliente"         
               title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                />
  
          
          </div>
        </div>
      )}</>
    );
}
