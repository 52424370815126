import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../style/login/login.css"
import { useAuth } from '../components/context/tokenadmin';
import { Container, Box, Modal, CircularProgress } from '@mui/material';
import stacker from "../img/login/stacker.gif";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

export const Login = ({ setShowNavbarFooter }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { setToken } = useAuth();
  const navigate = useNavigate(); 
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => setShowNavbarFooter(true);
  }, [setShowNavbarFooter]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setOpen(true);

    try {
      const response = await axios.post(`${backendURL}/api/login`, {
        email,
        password
      });

      // Obtener el token de la respuesta
      const token = response.data.access_token;

      // Almacenar el token en localStorage
      localStorage.setItem('token', token);

      // Configurar Axios para incluir el token en futuras solicitudes
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Establecer el token en el contexto
      setToken(token);

      // Redirigir al usuario
      navigate('/adminstacker');
    } catch (error) {
      setError('Error al ingresar al administrador, verifique usuario y contraseña');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div className='fondologin'>
      <Container maxWidth="md">
        <Box sx={{ background:"#004a55c2", display:"flex", width:"100%", height:"100%", position:"absolute", top:"0", left:"0", alignItems:"center", justifyContent:"center"}}>
          <div id="back">
            <canvas id="canvas" className="canvas-back"></canvas>
            <div className="backRight"></div>
            <div className="backLeft">
              <img src={stacker} alt="stacker" title="stacker" height="100%" width="100%" className='stackerloginimg'/>
            </div>
          </div>

          <div id="slideBox">
            <div className="topLayer">
              <div className="right">
                <div className="content">
                  <h2>Iniciar Sesión Stacker</h2>
                  <form id="form-login" method="post" onSubmit={handleLogin}>
                    <div className="form-element form-stack">
                      <label htmlFor="username-login" className="form-label">Usuario</label>
                      <input className="inputlogin" id="username-login" type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required />
                    </div>
                    <div className="form-element form-stack">
                      <label htmlFor="password-login" className="form-label">Contraseña</label>
                      <input className="inputlogin" id="password-login" type="password" value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required />
                    </div>
                    <div className="form-element form-submit">
                      <button id="logIn" className="login" type="submit">Ingresar</button>
                    </div>
                  </form>
                  {error && <p style={{color: 'red'}}>{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className='titulocorrectologin'>Iniciando sesión, espere...</h2>
          {loading ? <CircularProgress /> : <p>Iniciando sesión, espere...</p>}
        </Box>
      </Modal>
    </div>
  );
};
