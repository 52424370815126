import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../style/login/administrador.css"
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import "../../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";
import '../../style/nosotros/nosotros.css'
import '../../style/producto/producto.css';
import ReactPlayer from 'react-player';
import { TfiGallery } from "react-icons/tfi";


import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal,
    Grid,
    Select,
    MenuItem
} from "@mui/material";


export const Productosmaquinariaadmin = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [portadasData, setPortadasData] = useState([]);
    const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
    const [GaleriaData, setGaleriaData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isModalOpensgaleria, setIsModalOpensgaleria] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Estado de carga
    const [categories, setCategories] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [categoryFilter, setCategoryFilter] = useState(''); // Filtro por categoría
const [titleFilter, setTitleFilter] = useState('');

    const fetchCategoria = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/get/all/productos`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Enviando el token de autorización
                },
            });

            setPortadasData(response.data);  // Asignar el array `data` del backend
            if (response.status === 200 && response.data.length > 0) {
                const portadaGaleriaResponse = await axios.get(`${backendURL}/api/get/all/productos/?id=${response.data[0].id}`);
                setPortadasGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
                setGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
            }
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } finally {
            setIsLoading(false); // Finaliza la carga de datos
        }
    };

    useEffect(() => {
        fetchCategoria();
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            fetchCategoria(); // Actualizar la tabla cuando el modal se cierra
        }
    }, [isModalOpen]);

  useEffect(() => {
    if (token) {
        fetchCategoria();
    }
}, [token]);

  useEffect(() => {
    setShowNavbarFooter(false);

    // Verifica si el token no existe y redirige
    if (!token) {
        logout(); 
        navigate('/stacker'); // o la ruta que desees
    }

}, [setShowNavbarFooter, token, logout, navigate]);



const fetchCategories = async () => {
    try {
        const response = await axios.get(`${backendURL}/api/getCategoriasAdmin`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Enviando el token de autorización
            },
        });
        setCategories(response.data.data);  // Asignar el array `data` del backend
        console.log('Categorías cargadas:', response.data.data); // Verificar datos cargados
    } catch (error) {
        console.error('Error al obtener los datos de categorías:', error);
    }
};

useEffect(() => {
    fetchCategories();
}, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenModals = (categoria) => {
        setPortadasGaleriaData(categoria); // Set the selected portada
        setIsModalOpens(true);
    };
    const handleOpenGaleria = (categoria) => {
        setGaleriaData(categoria); // Set the selected portada
        setIsModalOpensgaleria(true);
    };

    const handleClosegaleria = () => {
        setIsModalOpensgaleria(false);
    };

    const handleCloseModals = () => {
        setIsModalOpens(false);
    };
    const handleDelete = async (categoriaId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`${backendURL}/api/deleteProductAdmin/${categoriaId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                fetchCategoria();  // Refresca la lista de portadas después de la eliminación
            } else {
                console.error('Error al eliminar la Categoria:', response);
            }
        } catch (error) {
            console.error('Error al eliminar la categoria:', error);
        } finally {
            setIsLoading(false);  // Detiene el indicador de carga
        }
    };

    const getCategoryDescription = (id_categoria) => {
        const category = categories.find(cat => cat.id == id_categoria);
        return category ? category.descripcion : 'Descripción no disponible';
    };

    const filteredPortadasData = portadasData.filter((categoria) => {
        const matchesCategory = categoryFilter
            ? categoria.id_categoria == parseInt(categoryFilter, 10)
            : true;
        const matchesTitle = titleFilter
            ? categoria.descripcion.toLowerCase().includes(titleFilter.toLowerCase())
            : true;

        return matchesCategory && matchesTitle;
    });
  

    useEffect(() => {
       
    }, [categoryFilter, titleFilter]);

    useEffect(() => {
      
    }, [portadasData]);

    return(
         <>
      <Container maxWidth="lg">
        
        <Box>
            <h2 className='tituloadministrador'> Productos Maquinaria Stacker</h2>
        </Box>
        <Box >
            <button  onClick={handleOpenModal} className='buttonaccionedit'> <MdOutlineCreateNewFolder /> <span>Crear</span></button> 
       
        </Box>
      
        <Box>
        <FormControl fullWidth margin="normal">
                <InputLabel id="category-filter-label">Filtrar por categoría</InputLabel>
                <Select
                    labelId="category-filter-label"
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    label="Filtrar por categoría"
                >
                    <MenuItem value="">
                        <em>Mostrar todas</em>
                    </MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.descripcion} {/* Asume que `name` es el nombre de la categoría */}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
        <Box sx={{marginBottom:"100px"}}> 
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={portadasData.length} // Pasando la longitud del array
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />   
                              <Table>
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Categoría</TableCell>
                                        <TableCell >Titulo</TableCell>
                                        <TableCell >Nombre</TableCell>
                                        <TableCell>Imagen</TableCell>
                                        <TableCell sx={{ width: "300px" }}>Video</TableCell>                                      
                                        <TableCell>Caracteristicas</TableCell>
                                        <TableCell>Siga Stacker</TableCell>
                                        <TableCell>Bateria</TableCell>
                                        <TableCell>Capacidad</TableCell>
                                        <TableCell>Altura</TableCell>
                                        <TableCell>Ancho pasillo</TableCell>
                                        <TableCell>Capacidad de bateria</TableCell>
                                        <TableCell>Caracteristicas especiales</TableCell>
                                        <TableCell>SEO</TableCell>
                                        <TableCell>Precio minimo</TableCell>
                                        <TableCell>Precio Maximo</TableCell>
                                        <TableCell sx={{ 
                                         position: 'sticky',
                                        right: '0',
                                        background: 'white',
                                        zIndex: '10', 
                                        textAlign: 'center'}}>
                                            Acciones
                                            </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {filteredPortadasData.length > 0 ? (
                                filteredPortadasData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((categoria) => (
                                            <TableRow key={categoria.id}>
                                                 <TableCell>  {getCategoryDescription(categoria.id_categoria)}</TableCell>
                                                <TableCell >{categoria.titulo}</TableCell>
                                                <TableCell >{categoria.nombre}</TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell sx={{ width: "300px" }}> <ReactPlayer
                                                url={categoria.video}
                                                controls={true}
                                                width="300px"
                                                height="auto"
                                            /></TableCell>
                                                <TableCell sx={{
                                                    
                                                         maxHeight: '150px',        // Altura máxima del TableCell
                                                         overflowY: 'auto',        // Habilita el scroll vertical
                                                         display: 'block',         // Permite que el contenido sea desplazable
                                                        
                                                }} > {categoria.caracteristicas}</TableCell>
                                                <TableCell>{categoria.siga_stacker}</TableCell>
                                                <TableCell>{categoria.bateria}</TableCell>
                                                <TableCell>{categoria.capacidad}</TableCell>
                                                <TableCell>{categoria.altura}</TableCell>
                                                <TableCell>{categoria.ancho_pasillo}</TableCell>
                                                <TableCell>{categoria.capacidad_bateria}</TableCell>
                                                <TableCell  sx={{
                                                         maxHeight: '150px',        // Altura máxima del TableCell
                                                         overflowY: 'auto',        // Habilita el scroll vertical
                                                         display: 'block',         // Permite que el contenido sea desplazable
                                                        
                                                }}>{categoria.caracteristicas_especiales}</TableCell>
                                                <TableCell>{categoria.seo}</TableCell>
                                                <TableCell>{categoria.precio_minimo}</TableCell>
                                                <TableCell>{categoria.precio_maximo}</TableCell>
                                                <TableCell sx={{ 
                                         position: 'sticky',
                                        right: '0',
                                        background: 'white',
                                        zIndex: '10', 
                                        textAlign: 'center'}}>
                                                <div>
                                               
                                        <button className='buttonaccionedit'  onClick={() => handleOpenModals(categoria)} > <FiEdit /> <span>Editar</span></button>
                                            
                                            
                                            <button className='buttonacciondelete'  onClick={() => handleDelete(categoria.id)}  disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                                </button>
                                        
                                        </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={18} align="center">
                                                No hay  productos maquinarias disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                   
                </TableContainer>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                bottom: "-1%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModal}><IoClose /></div>
                <Crearcard handleClose={handleCloseModal}  refreshPortadas={fetchCategoria} /> </Box>
                
            </Modal>

            <Modal open={isModalOpens} onClose={handleCloseModals}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "5%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModals}><IoClose /></div>
                <Editarcard
                 handleClose={handleCloseModals}
                   refreshPortadas={fetchCategoria} 
                selectedPortada={portadasGaleriaData}/>  </Box>
                
            </Modal>
            
        
        
      </Container>
    
    
    </>)
}



// Componente para crear la portada
const Crearcard = ( { handleClose, refreshPortadas}) => {
    const { token} = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        titulo: '',
        nombre:'',
        id_categoria:null,
        imagen: null,
        precio_minimo:'',
        precio_maximo:'',
        caracteristicas:'',
        video:'',     
        seo: '',
        siga_stacker:'',
        bateria:'',
        capacidad:'',
        altura:'',
        ancho_pasillo:'',
        capacidad_bateria:'',
        caracteristicas_especiales:'',
    });
   


    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getCategoriasAdmin`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Enviando el token de autorización
                },
            });

            setCategories(response.data.data);  // Asignar el array `data` del backend
            
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } 
    };

    useEffect(() => {
        fetchCategories();
    }, []);

 

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen'  ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.id_categoria) errors.id_categoria = 'la categoria es obligatorio';
        if (!formData.titulo) errors.titulo = 'El título es obligatorio';
        if (!formData.nombre) errors.nombre = 'El nombre es obligatorio';
        if (!formData.imagen) errors.imagen = 'La imagen  es obligatoria';
        if (!formData.precio_minimo) errors.precio_minimo = 'el precio minimo es obligatoria';
        if (!formData.precio_maximo) errors.precio_maximo = 'el precio maximo es obligatoria';
        if (!formData.caracteristicas) errors.caracteristicas = 'la caracteristicas es obligatoria';
        if (!formData.caracteristicas_especiales) errors.caracteristicas_especiales = 'la caracteristicas especiales es obligatoria';
        if (!formData.siga_stacker) errors.siga_stacker = 'el siga stacker es obligatorio';
        if (!formData.bateria) errors.bateria = 'la bateria es obligatorio';
        if (!formData.capacidad) errors.capacidad = 'la capacidad es obligatorio';
        if (!formData.capacidad_bateria) errors.capacidad_bateria = 'la capacidad_bateria es obligatorio';
        if (!formData.bateria) errors.bateria = 'la bateria es obligatorio';
        if (!formData.altura) errors.altura = 'el altura es obligatorio';
        if (!formData.ancho_pasillo) errors.ancho_pasillo = 'el ancho pasillo es obligatorio';
        if (!formData.seo) errors.seo = 'El texto SEO es obligatorio';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!validateForm()) {
            setErrorModalOpen(true);
            return;
        }

        setIsLoading(true);
    
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);

            
        }
        try {  const response = await axios.post(`${backendURL}/api/createProductAdmin`,formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            
            
            if (response.data.success) {
                await refreshPortadas(); 
                 setSuccessModalOpen(true);
                
            } else {
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorModalOpen(true);
            console.error('Error creating portada:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    
  
   
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Crear Nuevo producto maquinaria </Typography>
          
            <form onSubmit={handleSubmit}> 
            <FormControl fullWidth margin="normal">
            <InputLabel shrink htmlFor="id_categoria" sx={{marginTop:'-7px'}}>Categoría</InputLabel>
            <Select
                        fullWidth
                        margin="normal"
                        error={!!formErrors.id_categoria}
                        helperText={formErrors.id_categoria}
                        name="id_categoria"
                        value={formData.id_categoria}
                        onChange={handleChange}
                    > 
                   {categories.length > 0 ? (
            categories.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.descripcion}
                </MenuItem>
            ))
        ) : (
            <MenuItem value="">
                No hay categorías disponibles
            </MenuItem>
        )}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Titulo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.titulo}
                    helperText={formErrors.titulo}
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleChange}
                />
                </FormControl>
                 
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Nombre
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.nombre}
                    helperText={formErrors.nombre}
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                </FormControl>   
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        video
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.video}
                    helperText={formErrors.video}
                    name="video"
                    value={formData.video}
                    onChange={handleChange}
                />
                </FormControl>       
                
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        Imagen  
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        error={!!formErrors.imagen}
                    helperText={formErrors.imagen}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.caracteristicas}
                    helperText={formErrors.caracteristicas}
                    name="caracteristicas"
                    value={formData.caracteristicas}
                    onChange={handleChange}
                    multiline
                />
                </FormControl><FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas especiales 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.caracteristicas_especiales}
                    helperText={formErrors.caracteristicas_especiales}
                    name="caracteristicas_especiales"
                    value={formData.caracteristicas_especiales}
                    onChange={handleChange}
                    multiline
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Precio minimo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.precio_minimo}
                    helperText={formErrors.precio_minimo}
                    name="precio_minimo"
                    value={formData.precio_minimo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Precio maximo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.precio_maximo}
                    helperText={formErrors.precio_maximo}
                    name="precio_maximo"
                    value={formData.precio_maximo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Capacidad 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.capacidad}
                    helperText={formErrors.capacidad}
                    name="capacidad"
                    value={formData.capacidad}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Bateria 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.bateria}
                    helperText={formErrors.bateria}
                    name="bateria"
                    value={formData.bateria}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Altura 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.altura}
                    helperText={formErrors.altura}
                    name="altura"
                    value={formData.altura}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Ancho pasillo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.ancho_pasillo}
                    helperText={formErrors.ancho_pasillo}
                    name="ancho_pasillo"
                    value={formData.ancho_pasillo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Capacidad bateria 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.capacidad_bateria}
                    helperText={formErrors.capacidad_bateria}
                    name="capacidad_bateria"
                    value={formData.capacidad_bateria}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        siga stacker 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.siga_stacker}
                    helperText={formErrors.siga_stacker}
                    name="siga_stacker"
                    value={formData.siga_stacker}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                      SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="seo"
                    value={formData.seo}
                    onChange={handleChange}
                    error={!!formErrors.seo}
                    helperText={formErrors.seo}
                />
                </FormControl>
                <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>

            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
            <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">¡Categoria creada correctamente!</Typography>
                    <button className='buttonaccionedit' onClick={() => setSuccessModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>

            {/* Error Modal */}
            <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
            <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">Ocurrió un error. Inténtalo de nuevo.</Typography>
                    <button className='buttonaccionedit' onClick={() => setErrorModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>
        </Box>
    );
};

const Editarcard = ({ handleClose, refreshPortadas, selectedPortada }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        titulo: '',
        nombre:'',
        id_categoria:null,
        imagen: null,
        precio_minimo:'',
        precio_maximo:'',
        caracteristicas:'',
        video:'',     
        seo: '',
        siga_stacker:'',
        bateria:'',
        capacidad:'',
        altura:'',
        ancho_pasillo:'',
        capacidad_bateria:'',
        caracteristicas_especiales:'',
    });

    
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getCategoriasAdmin`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Enviando el token de autorización
                },
            });

            setCategories(response.data.data);  // Asignar el array `data` del backend
            
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } 
    };

    useEffect(() => {
        fetchCategories();
    }, []);

 

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen'  ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    
    useEffect(() => {
        if (selectedPortada) {
            setFormData({
                titulo: selectedPortada.titulo,
                seo: selectedPortada.seo,
                imagen: selectedPortada.imagen,
                video: selectedPortada.video,
                nombre: selectedPortada.nombre,
                id_categoria: selectedPortada.id_categoria,
                precio_minimo: selectedPortada.precio_minimo,
                precio_maximo: selectedPortada.precio_maximo,
                siga_stacker: selectedPortada.siga_stacker,
                bateria: selectedPortada.bateria,
                capacidad: selectedPortada.capacidad,
                altura: selectedPortada.altura,
                ancho_pasillo: selectedPortada.ancho_pasillo,
                capacidad_bateria: selectedPortada.capacidad_bateria,
                caracteristicas_especiales: selectedPortada.caracteristicas_especiales,
                caracteristicas: selectedPortada.caracteristicas

            });
        }
    }, [selectedPortada]);
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('titulo', formData.titulo);
        formDataToSend.append('seo', formData.seo);
        formDataToSend.append('nombre', formData.nombre);
        formDataToSend.append('video', formData.video);
        formDataToSend.append('id_categoria', formData.id_categoria);
        formDataToSend.append('precio_minimo', formData.precio_minimo);
        formDataToSend.append('precio_maximo', formData.precio_maximo);
        formDataToSend.append('siga_stacker', formData.siga_stacker);
        formDataToSend.append('bateria', formData.bateria);
        formDataToSend.append('capacidad', formData.capacidad);
        formDataToSend.append('altura', formData.altura);
        formDataToSend.append('ancho_pasillo', formData.ancho_pasillo);
        formDataToSend.append('bateria', formData.bateria);
        formDataToSend.append('capacidad_bateria', formData.capacidad_bateria);
        formDataToSend.append('caracteristicas_especiales', formData.caracteristicas_especiales);
        formDataToSend.append('caracteristicas', formData.caracteristicas);
        
        if (formData.imagen) {
            formDataToSend.append('imagen', formData.imagen);
        }
       
        

        // Verifica los datos que se están enviando
        for (let [key, value] of formDataToSend.entries()) {
            console.log(key, value);
        }

        const response = await axios.post(`${backendURL}/api/editProductAdmin/${selectedPortada.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            refreshPortadas();
            handleClose();
        } else {
            console.error('Error al actualizar la portada:', response);
        }
    } catch (error) {
        console.error('Error al actualizar la portada:', error);
    } finally{
        setIsLoading(false);
        handleClose();
    }

    };
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Editar producto maquinaria</Typography>
            {/* Campos del formulario */}
            <form onSubmit={handleSubmit}> 
            <FormControl fullWidth margin="normal">
            <InputLabel shrink htmlFor="id_categoria" sx={{marginTop:'-7px'}}>Categoría</InputLabel>
            <Select
                        fullWidth
                        margin="normal"
                        name="id_categoria"
                        value={formData.id_categoria}
                        onChange={handleChange}
                    > 
                   {categories.length > 0 ? (
            categories.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.descripcion}
                </MenuItem>
            ))
        ) : (
            <MenuItem value="">
                No hay categorías disponibles
            </MenuItem>
        )}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Titulo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleChange}
                />
                </FormControl>
                 
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Nombre
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                </FormControl>   
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        video
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="video"
                    value={formData.video}
                    onChange={handleChange}
                />
                <ReactPlayer
                                                url={formData.video}
                                                controls={true}
                                                width="300px"
                                                height="auto"
                                            />
                </FormControl>       
                
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        Imagen  
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                      {formData.imagen && (
                        <img
                            src={`${backendURL}/${formData.imagen}`}
                            alt={formData.seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="caracteristicas"
                    value={formData.caracteristicas}
                    onChange={handleChange}
                    multiline
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas especiales 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="caracteristicas_especiales"
                    value={formData.caracteristicas_especiales}
                    onChange={handleChange}
                    multiline
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Precio minimo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="precio_minimo"
                    value={formData.precio_minimo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Precio maximo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="precio_maximo"
                    value={formData.precio_maximo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Capacidad 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="capacidad"
                    value={formData.capacidad}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Bateria 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="bateria"
                    value={formData.bateria}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Altura 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="altura"
                    value={formData.altura}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Ancho pasillo 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="ancho_pasillo"
                    value={formData.ancho_pasillo}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Capacidad bateria 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="capacidad_bateria"
                    value={formData.capacidad_bateria}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        siga stacker 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="siga_stacker"
                    value={formData.siga_stacker}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                      SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="seo"
                    value={formData.seo}
                    onChange={handleChange}
                />
                </FormControl>
                <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>
        </Box>
    );
};
