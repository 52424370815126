import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../../style/login/administrador.css"
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import "../../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";

import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal
} from "@mui/material";

export const Cardadminhome = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [portadasData, setPortadasData] = useState([]);
    const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Estado de carga
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const fetchCategoria = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getCategoriasAdmin`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Enviando el token de autorización
                },
            });

            setPortadasData(response.data.data);  // Asignar el array `data` del backend
            if (response.status === 200 && response.data.data.length > 0) {
                const portadaGaleriaResponse = await axios.get(`${backendURL}/api/getCategoriasAdmin?id=${response.data.data[0].id}`);
                setPortadasGaleriaData({ [response.data.data[0].id]: portadaGaleriaResponse.data });
            }
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } finally {
            setIsLoading(false); // Finaliza la carga de datos
        }
    };

    useEffect(() => {
        fetchCategoria();
    }, []);


  useEffect(() => {
    if (token) {
        fetchCategoria();
    }
}, [token]);

  useEffect(() => {
    setShowNavbarFooter(false);

    // Verifica si el token no existe y redirige
    if (!token) {
        logout(); 
        navigate('/stacker'); // o la ruta que desees
    }

}, [setShowNavbarFooter, token, logout, navigate]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenModals = (categoria) => {
        setPortadasGaleriaData(categoria); // Set the selected portada
        setIsModalOpens(true);
    };

    const handleCloseModals = () => {
        setIsModalOpens(false);
    };

    const handleDelete = async (categoriaId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`${backendURL}/api/deleteCategoria/${categoriaId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                fetchCategoria();  // Refresh the portadas list after deletion
            } else {
                console.error('Error al eliminar la Categoria:', response);
            }
        } catch (error) {
            console.error('Error al eliminar la categoria:', error);
        } finally {
            setIsLoading(false);  // Stop loading indicator
        }
    };

    return(
         <>
      <Container maxWidth="lg">
        <Box>
            <h2 className='tituloadministrador'> Categoría maquinaria Stacker</h2>
        </Box>
        <Box >
            <button  onClick={handleOpenModal} className='buttonaccionedit'> <MdOutlineCreateNewFolder /> <span>Crear</span></button> 
       
        </Box>
        <Box>
            <div className='contienetitulovistaprevia'>
                <span className='tituloovistaprevia'>Vista Previa Categoría Maquinaria inicio</span>
                </div>
            <div>   {portadasData.length > 0 ? (
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          breakpoints={{
            240: {
              slidesPerView: 1,
              spaceBetween: 4,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            880: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 1,
            },
            1565: {
              slidesPerView: 5,
              spaceBetween: 1,
            },
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="sliderhomeproducto"
        >
          {portadasData.map((categoria) => (
            <SwiperSlide key={categoria.id} className="categoria-slider-home" >
              <div className="categoria-home" >
                <div className="cardcontenidocategoria">
                  <div className="imagen-categoria-producto-home">
                    {categoria.imagen && (
                     
                        <img
                          src={`${backendURL}/${categoria.imagen}`}
                            height="100%"
                            width="100%"
                        />
                      
                    )}
                  </div>
                  <div className="info-categoria-home">
                    <button aria-hidden="true" tabindex="-1" className="seobuttonhidden ">
                  <p className="titulo-contenido-categoria" aria-hidden="true" tabindex="-1">{categoria.descripcion} </p>
                  </button>
                 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Cargando categorías...</p>
      )}</div>

<div className='contienetitulovistaprevia'>
                <span className='tituloovistaprevia'>Vista Previa Categoría Maquinaria vista producto</span>
                </div>
                <div>
                <section className="categoria-prducto">
        
        <Swiper
                slidesPerView={4}
                spaceBetween={1}
                breakpoints={{
                  240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  500: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 1,
                  },
                  1000: { slidesPerView: 4, spaceBetween: 1 },
                  1100: {
                    slidesPerView: 5,
                    spaceBetween: 2,
                  },
                  1566: {
                    slidesPerView: 6,
                    spaceBetween: 1,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                
                modules={[ Autoplay, Navigation]}
                className="producto-servicios"
              >
                {portadasData.map((categoria) => (
                <SwiperSlide key={categoria.id} style={{ 
                  
                display:"flex", alignItems:"center", justifyContent:"center"
              }} >
                  <div style={{padding:"0px 10px"}} >
                  <button  className="buttoncategoria">
                  <div className="icono-categoria"> 
                  {categoria.imagen && (
              <img
              className="maquina-cateogria"
              style={{width:"80px"}}
                src={`${backendURL}/${categoria.imagen}`}
                alt={categoria.seo || categoria.nombre}
                title={categoria.seo || categoria.nombre}
                />
                
                )} 
                
                
                </div>
                <div className="contienetitulocategoriapropductopagina">
                   <strong>
                     <h2> {categoria.descripcion}</h2>
                    </strong>
                    </div>
                
                </button>
              </div> </SwiperSlide>         
          ))}</Swiper>
       
       
      </section>
                </div>
        </Box>
        <Box sx={{marginBottom:"100px"}}> 
        <TableContainer component={Paper}>
        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={portadasData.length} // Pasando la longitud del array
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />   
                              <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Imagen</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {portadasData.length > 0 ? (
                                        portadasData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((categoria) => (
                                            <TableRow key={categoria.id}>
                                                <TableCell>{categoria.nombre}</TableCell>
                                                <TableCell>{categoria.descripcion}</TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell>
                                                <div>
                                        <button className='buttonaccionedit'  onClick={() => handleOpenModals(categoria)} > <FiEdit /> <span>Editar</span></button>
                                            <button className='buttonacciondelete'  onClick={() => handleDelete(categoria.id)}  disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                                </button>
                                        
                                        </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No hay categorías disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                   
                </TableContainer>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box sx={{
                width:"800px",
                p:4,
                position:"relative",
                top:"10%",
                left:"20%",
                borderRadius:"10px",
            }}>
                <div className='closemodaladmin' onClick={handleCloseModal}><IoClose /></div>
                <Crearcard handleClose={handleCloseModal}  refreshPortadas={fetchCategoria} /> </Box>
                
            </Modal>

            <Modal open={isModalOpens} onClose={handleCloseModals}>
            <Box sx={{
                width:"800px",
                p:4,
                position:"relative",
                top:"10%",
                left:"20%",
                borderRadius:"10px",
            }}>
                <div className='closemodaladmin' onClick={handleCloseModals}><IoClose /></div>
                <Editarcard
                 handleClose={handleCloseModals}
                   refreshPortadas={fetchCategoria} 
                selectedPortada={portadasGaleriaData}/>  </Box>
                
            </Modal>
        
        
      </Container>
    
    
    </>)
}



// Componente para crear la portada
const Crearcard = ( { handleClose, refreshPortadas}) => {
    const { token} = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        imagen: null,
        alt_seo: ''
    });

    

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen') {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.nombre) errors.nombre = 'El título es obligatorio';
        if (!formData.descripcion) errors.descripcion = 'La descripción es obligatoria';
        if (!formData.imagen) errors.imagen = 'La imagen es obligatoria';
        if (!formData.alt_seo) errors.alt_seo = 'El texto SEO es obligatorio';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!validateForm()) {
            setErrorModalOpen(true);
            return;
        }

        setIsLoading(true);
    
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
        try {  const response = await axios.post(`${backendURL}/api/crearCategoriaAdmin`,formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            
            
            if (response.data.success) {
                setSuccessModalOpen(true);
                refreshPortadas(); 
            } else {
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorModalOpen(true);
            console.error('Error creating portada:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    
  
   
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Crear Nueva Card inicio</Typography>
          
            <form onSubmit={handleSubmit}> 
                 <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Nombre 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.nombre}
                    helperText={formErrors.nombre}
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                </FormControl>
                   <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Descripción 
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    error={!!formErrors.descripcion}
                    helperText={formErrors.descripcion}
                />
                </FormControl>
                
                
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        Imagen 
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        error={!!formErrors.imagen}
                    helperText={formErrors.imagen}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                      SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                    error={!!formErrors.alt_seo}
                    helperText={formErrors.alt_seo}
                />
                </FormControl>
                <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>

            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
                <Box sx={{ p: 4, backgroundColor: 'white', outline: 'none', borderRadius: '8px' }}>
                    <Typography variant="h6" align="center">¡Categoria creada correctamente!</Typography>
                    <button onClick={() => setSuccessModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>

            {/* Error Modal */}
            <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
                <Box sx={{ p: 4, backgroundColor: 'white', outline: 'none', borderRadius: '8px' }}>
                    <Typography variant="h6" align="center">Ocurrió un error. Inténtalo de nuevo.</Typography>
                    <button onClick={() => setErrorModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>
        </Box>
    );
};

const Editarcard = ({ handleClose, refreshPortadas, selectedPortada }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        imagen: null,
        alt_seo: ''
    });

    
    useEffect(() => {
        if (selectedPortada) {
            setFormData({
                nombre: selectedPortada.nombre,
                descripcion: selectedPortada.descripcion,
                alt_seo: selectedPortada.alt_seo,
                imagen: selectedPortada.imagen,
            });
        }
    }, [selectedPortada]);
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen' ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('nombre', formData.nombre);
        formDataToSend.append('descripcion', formData.descripcion);
        formDataToSend.append('alt_seo', formData.alt_seo);
        
        if (formData.imagen) {
            formDataToSend.append('imagen', formData.imagen);
        }
        

        // Verifica los datos que se están enviando
        for (let [key, value] of formDataToSend.entries()) {
            console.log(key, value);
        }

        const response = await axios.post(`${backendURL}/api/editarCategoriaAdmin/${selectedPortada.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            refreshPortadas();
            handleClose();
        } else {
            console.error('Error al actualizar la portada:', response);
        }
    } catch (error) {
        console.error('Error al actualizar la portada:', error);
    } finally{
        setIsLoading(false);
        handleClose();
    }

    };
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Editar card inicio</Typography>
            {/* Campos del formulario */}
            <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                      Nombre
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                 </FormControl>
                 <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        Descripción
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                 
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />
                 </FormControl>
              
                  <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{
                        top:"-7px  "
                    }}>
                        Imagen 
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen"
                        id="imagen"
                        
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {formData.imagen && (
                        <img
                            src={`${backendURL}/${formData.imagen}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                   
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                />
                 </FormControl>
                 <button className='buttonaccionedit' type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>
        </Box>
    );
};