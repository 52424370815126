import { Grid, Container } from "@mui/material";
import "../../style/home/home.css";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css'; 


export default function ServiciosHome() {
    const [servicios, setServicios] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
        // Realiza la solicitud a la API al cargar el componente
        axios.get(`${backendURL}/api/get/all/videos`)
          .then(response => {
            // Filtra los servicios que tienen tipo 'portada' en su array
            const portadaServicios = response.data.filter(videos => videos.tipo === 'servicios');
            setServicios(portadaServicios);
          })
          .catch(error => {
            console.error('Error fetching servicios:', error);
          });
      }, []);

      useEffect(() => {
        AOS.init({
          // Configura las opciones aquí
          duration: 8000,
        });
      }, []);
    
    return (
        <>
         <Container maxWidth="xl" >
        <section className="titulo-servicios-principal" data-aos="fade-down">
       
            <p className="titulo-principal-servicio">NUESTROS SERVICIOS STACKER</p>
        </section>
      
        <Grid container sx={{marginTop:'70px'}} >
        {servicios.map(servicio => (
          <Grid key={servicio.id} item xs={6} sm={3} md={3} lg={3} sx={{padding:'10px', '@media (max-width: 400px)': {padding:'1px'}}} data-aos="fade-down">
            <a href={servicio.url} title="servicios">
              <div className="card-servicio-home">
                <div className="img-contenedor-servicio-home">
                  {servicio.imagen && (
                    <picture>
                    <source srcSet={`${backendURL}/${servicio.imagen}`} type="image/webp" />
                    <img
                        src={`${backendURL}/${servicio.imagen}`}
                        alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                        title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                        className="contenedorservicioshome"
                          height="100%"
                          width="100%"
                    />
                </picture>
                  )}</div>
                  <div className="info-servicio">
                    <h3 className="titulo-servicios-home" aria-hidden="true" tabIndex="-1">{servicio.titulo}</h3>
                   
                    
                    <div className="contieneinfodescripcionservicio">
                    <p className='subtitulodos-servicio-home' aria-hidden="true" tabIndex="-1">{servicio.descripcion}</p>
                    </div>
                  </div>
                
                <button className="button-servicios-home" aria-hidden="true" tabIndex="-1">{servicio.titulo}</button>
              </div> 
             
            </a>
          </Grid>
        ))}
        </Grid>
        </Container>
        </>
    );
}
