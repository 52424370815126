import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../style/home/home.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Grid } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoaderModal from '../otroscomponentes/louding';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";



export default function Portadahome() {
  useEffect(() => {
    AOS.init({ duration: 8000 });
  }, []);
  
  const [windowWidth, setWindowWidth] = useState(0);
  const [portadasData, setPortadasData] = useState([]);
  const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/get/all/portadas`);
        setPortadasData(response.data);
        if (response.status === 200) {
          if (response.data.length > 0) {
            const portadaGaleriaResponse = await axios.get(`${backendURL}/api/get/all/portadasgaleria?id=${response.data[0].id}`);
            setPortadasGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
            setIsLoading(false); // Hide loader when data is ready
          }
        } else {
          console.error('Error al obtener los datos de portadas:', response);
        }
      } catch (error) {
        console.error('Error al obtener los datos de portadas:', error);
      }
    };

    fetchData();
  }, []);

  const handleImageLoad = () => {
    // Cuando todas las imágenes hayan sido cargadas, se oculta el loader
    setIsLoading(false);
  };

  const MIN_SLIDES_FOR_LOOP = 3;
  const getSlidesForSwiper = (slides) => {
    if (slides.length < MIN_SLIDES_FOR_LOOP) {
      return [...slides, ...slides];
    }
    return slides;
  };

  return (
    <>
      {isLoading && <LoaderModal />}
      <section className='portada-home'>
     
      {portadasData.length > 1 ? (
  <Swiper
    slidesPerView={1}
    spaceBetween={1}
    modules={[Autoplay, EffectFade]}
    autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    loop={true}
    className="mySwiper"
  >
    {portadasData.map((portadaItem) => (
      <SwiperSlide key={portadaItem.id}>
        <div className='contenedor-img'>
          <div className="portadanosotroshome">
            <img
              src={windowWidth < 840 ? `${backendURL}/${portadaItem.imagen_responsive}` : `${backendURL}/${portadaItem.imagen_web}`}
              className="portada-nosotros"
              alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
              title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
              height="100%"
              width="100%"
            />
          </div>
          <div className='contenedor-portada-produtohome'>
            <Grid container>
              <Grid item xs={6} data-aos="fade-down" sx={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                <div className='info-portada-home'>
                  <p className='titulo-portada-home'>{portadaItem.titulo}</p>
                  <h1 className='subtitulo-portada-home'>{portadaItem.descripcion}</h1>
                  <h2 style={{ color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador, grúa horquilla, pasillo angosto, alzahombres, reach stacker, transpaleta.</h2>
                  <div className='boton-portada-home'>
                    <a href={portadaItem.url} title="stacker" rel="nofollow">
                      <button className="buttons type2" title="stacker" aria-hidden="true" tabIndex="-1">
                        <span className="btn-txts" aria-hidden="true" tabIndex="-1">COTIZAR</span>
                      </button>
                    </a>
                  </div>
                </div>
              </Grid>
                <Grid item xs={6} data-aos="fade-down">
                  <div className='contenedor-maquina-portada-home'>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    modules={[Autoplay, EffectFade]}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    className="mySwiper"
                    onSwiper={(swiper) => {
                      swiper.on('imagesReady', handleImageLoad); // Detect when all images are ready
                    }}
                  >
                    {getSlidesForSwiper(portadasGaleriaData[portadaItem.id] || []).map((galeriaItem) => (
                      <SwiperSlide key={galeriaItem.id} style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <div className='contieneimagen-portadaslider' >
                            <img
                              src={`${backendURL}/${galeriaItem.imagen_web}`}
                              alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                              title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                              className='maquinariaportada'
                                height="100%"
                                width="100%"
                            />
                          
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  </div>
                </Grid>
              </Grid>
              </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
) : (
  portadasData.map((portadaItem) => (
    <div key={portadaItem.id} className='contenedor-img'>
      <div className="portadanosotroshome">
        <img
          src={windowWidth < 840 ? `${backendURL}/${portadaItem.imagen_responsive}` : `${backendURL}/${portadaItem.imagen_web}`}
          className="portada-nosotros"
          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
          height="100%"
          width="100%"
        />
      </div>
      <div className='contenedor-portada-produtohome'>
        <Grid container>
          <Grid item xs={6} data-aos="fade-down" sx={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div className='info-portada-home'>
              <p className='titulo-portada-home'>{portadaItem.titulo}</p>
              <h1 className='subtitulo-portada-home'>{portadaItem.descripcion}</h1>
              <h2 style={{ color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador, grúa horquilla, pasillo angosto, alzahombres, reach stacker, transpaleta.</h2>
              <div className='boton-portada-home'>
                <a href={portadaItem.url} title="stacker" rel="nofollow">
                  <button className="buttons type2" title="stacker" aria-hidden="true" tabIndex="-1">
                    <span className="btn-txts" aria-hidden="true" tabIndex="-1">COTIZAR</span>
                  </button>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} data-aos="fade-down">
                  <div className='contenedor-maquina-portada-home'>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    modules={[Autoplay, EffectFade]}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    className="mySwiper"
                    onSwiper={(swiper) => {
                      swiper.on('imagesReady', handleImageLoad); // Detect when all images are ready
                    }}
                  >
                    {getSlidesForSwiper(portadasGaleriaData[portadaItem.id] || []).map((galeriaItem) => (
                      <SwiperSlide key={galeriaItem.id} style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <div className='contieneimagen-portadaslider' >
                            <img
                              src={`${backendURL}/${galeriaItem.imagen_web}`}
                              alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                              title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                              className='maquinariaportada'
                                height="100%"
                                width="100%"
                            />
                          
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  </div>
                </Grid>
        </Grid>
      </div>
    </div>
  ))
)}
      </section>
    </>
  );
};