import React, { useState, useEffect, useCallback } from "react";
import "../../style/navbar/navbar.css";
import {
  Badge, IconButton, Box, Drawer, SwipeableDrawer, Grid,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from '@mui/material/styles';
import { useCarrito } from "../context/carrito-cotizar";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { BsTelephoneFill, BsEnvelope } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import logo from "../../img/navbar/logo.png";

// Datos de enlaces y redes sociales
const links = [
  { label: 'Productos', route: '/productos' },
  { label: 'Racks', route: '/catalogo' },
  { label: 'Servicio Técnico', route: '/servicios' },
  { label: 'Contacto', route: '/contacto' },
];

const socialLinks = [
  { icon:  BsEnvelope , url: "mailto:contacto@stacker.cl", title: "mail" },
  { icon: BsTelephoneFill, url: "tel:+56990792892", title: "telefono" },
  { icon: FaWhatsapp, url: "https://wa.me/+56990792892?text=Quiero información o cotizar por sus servicios y productos", title: "WhatsApp" },
  { icon: FaInstagram, url: "https://www.instagram.com/stacker_chile/", title: "instagram" },
  { icon: FaLinkedinIn, url: "https://www.linkedin.com/company/stacker-itcl/", title: "LinkedIn" },
  { icon: FaFacebookF, url: "https://www.facebook.com/StackerChile", title: "Facebook" },
  { icon: FaYoutube, url: "https://www.youtube.com/@StackerChile/featured", title: "YouTube" },
];

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export const Navbar = () => {
  const { cantidadProductos } = useCarrito();
  const [menuVisible, setMenuVisible] = useState(true);
  const [drawerState, setDrawerState] = useState({ right: false });

   // Función para manejar la apertura y cierre del Drawer
   const toggleDrawer = useCallback((anchor, open) => (e) => {
    // Verificar si el evento es de teclado y si es una tecla restringida
    if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  }, [drawerState]);

  useEffect(() => {
    const handleResize = () => setMenuVisible(window.innerWidth <= 850);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderSocialLinks = () => (
    socialLinks.map(({ icon: Icon, url, title }) => (
      <a href={url} target="_blank" title={title} key={title}>
        <IconButton ><Icon className="iconos-navbar"/></IconButton>
      </a>
    ))
  );

  const renderNavLinks = () => (
    links.map(({ label, route }) => (
      <li key={route} className="ruta-nav"  aria-hidden="true" tabIndex="-1">
        <a className="link-ruta-nav" href={route} title={label} aria-hidden="true" tabIndex="-1">
          <p className="label-nav"  aria-hidden="true" tabIndex="-1">{label}</p>
        </a>
      </li>
    ))
  );
  const renderNavLinksmovil = () => (
    links.map(({ label, route }) => (
      <li key={route} className="label-nav-mobil"  aria-hidden="true" tabIndex="-1">
        <a className="link-ruta-nav" href={route} title={label} aria-hidden="true" tabIndex="-1" >
          <p className="ruta-nav-mobil"  aria-hidden="true" tabIndex="-1">{label}</p>
        </a>
      </li>
    ))
  );

  const renderDrawerList = (anchor) => (
    <Drawer
      anchor={anchor}
      open={drawerState[anchor]}
      onClose={toggleDrawer(anchor, false)}
      sx={{ '& .MuiDrawer-paper': { backgroundColor: '#115c67' } }}
    >
      <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: '#115c67', color: '#fff' }}>
        <ul className="rutas-navbar-mobil" aria-hidden="true" tabIndex="-1">
          {renderNavLinksmovil()}
        </ul>
        <Box className="conteneredor-redes-sociales-navbar">
          {renderSocialLinks()}
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <header className="header">
      {menuVisible ? (
        <section className="contenedor-navbar menu-mobile">
          <Grid container justifyContent="space-between">
            <Grid item>
              <a href="/" title="home">
                <img src={logo} alt="maquinaria" title="stacker" className="logomaquina"   height="100%"
          width="100%" />
              </a>
            </Grid>
            <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <a href="/cart" title="carrito">
                <IconButton aria-label="cart" sx={{color:'#fff'}}>
                  <StyledBadge badgeContent={cantidadProductos}>
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </a>
            </Grid>
            <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="contenedor-menu">
              <input type="checkbox" className="checkbox-navbar" id="checkbox" checked={drawerState.right} onChange={toggleDrawer("right", !drawerState.right)} />
              <label htmlFor="checkbox" className="toggle">
                <div className="bars" id="bar1"></div>
                <div className="bars" id="bar2"></div>
                <div className="bars" id="bar3"></div>
              </label>
              </div>
              <SwipeableDrawer
                className="listado-menu"
                anchor="right"
                open={drawerState.right}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
              >
                {renderDrawerList("right")}
              </SwipeableDrawer>
            </Grid>
          </Grid>
        </section>
      ) : (
        <section className="contenedor-navbar">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <a href="/" title="home">
                <img src={logo} alt="maquinaria" title="stacker" className="logomaquina"   height="100%"
          width="100%" />
              </a>
            </Grid>
            <Grid item>
              <ul className="rutas-navbar">
                {renderNavLinks()}
              </ul>
            </Grid>
            <Grid item>
              <a href="/cart" title="carrito">
                <IconButton aria-label="cart"  className="contiene-icono-shop">
                  <StyledBadge badgeContent={cantidadProductos} sx={{color:'#fff'}}>
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </a>
            </Grid>
            <Grid item>
              <div className="conteneredor-redes-sociales-navbar">
                {renderSocialLinks()}
              </div>
            </Grid>
          </Grid>
        </section>
      )}
    </header>
  );
};

