
import React, { useEffect, useState } from 'react';
import { Grid, Container } from "@mui/material"

import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 


import"../../style/estrcutura producto/productestruc.css"
import { useCarrito } from '../context/carrito-cotizar'; 
import { useCarritoContexto } from '../context/cotizar-final';


 
/* imagenes */
import check from "../../img/racks/estructura/check.png"

export default function Portadaproducto({ producto }) {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { agregarProductoAlCarrito } = useCarrito(); 
  const { agregarcotizar } = useCarritoContexto();
  const [activarAnimacion, setActivarAnimacion] = useState(false);

  useEffect(() => {
    // Activa la animación después de un retardo de 1 segundo
    const timer = setTimeout(() => setActivarAnimacion(true), 1000);
    return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  }, []);

  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);

  const cotizarProductos = () => {
    const productofinal = {
      id:producto.id,
      tipo: "maquina",
      cantidad:"1",
      imagen:producto.imagen,
      precio:"Arriendo o Venta",
     nombre:producto.nombre 
    };
    agregarcotizar(productofinal)
  };

  const handleAgregarAlCarrito = () => {
    const productofinal = {
      id:producto.id,
      tipo: "maquina",
      cantidad:"1",
      imagen:producto.imagen,
      precio:"Arriendo o Venta",
     nombre:producto.nombre 
    };
    agregarProductoAlCarrito(productofinal);
  };

return(
<> 
 <Container  maxWidth="lg" >
      <div className="informacion-producto-seleccion" data-aos="fade-down">
      
        <h1 className="titulo-informacion-producto-selecion">{producto.nombre}</h1>
       <hr className={`separador-seleccion-producto ${activarAnimacion ? 'activar' : ''}`} />
      </div>

      <section className="producto-principal-seleccion" data-aos="fade-down">
        <section className="descripcion-producto">
          <div className="imagen-descripcion-producto-prnicpal">
            <div className="img-producto">
              {producto.imagen && (
                <img
                  src={`${backendURL}/${producto.imagen}`}
                  alt={producto.seo}
                  className='contieneimagendescripcion'
                  title={producto.seo}
                />
              )}
            </div>


     <div className='contenedor-ventaja-producto' data-aos="fade-down">
        <Grid container className='todo-ventaja'>
            <Grid item xs={12} sm={6} md={4}>
                <div className='contenedor-check-productos'>
                    <img src={check} alt="stacker" title="stacker" className='imagen-check' />
                    <p className='titulo-check-prducto'> Toneladas {producto.capacidad}</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-aos="fade-down">
            <div className='contenedor-check-productos'>
                    <img src={check} alt="stacker" title="stacker" className='imagen-check'/>
                    <p className='titulo-check-prducto'> Energía {producto.bateria}</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <div className='contenedor-check-productos'>
                    <img src={check} alt="stacker" title="stacker" className='imagen-check'/>
                    <p className='titulo-check-prducto'> Capacidad Energía {producto.capacidad_bateria}</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <div className='contenedor-check-productos'>
                    <img src={check} alt="stacker" title="stacker" className='imagen-check'/>
                    <p className='titulo-check-prducto'> Altura{producto.altura}</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <div className='contenedor-check-productos'>
                    <img src={check} alt="stacker" title="stacker" className='imagen-check'/>
                    <p className='titulo-check-prducto'>Ancho pasillo {producto.ancho_pasillo}</p>
                </div>
            </Grid>
           
            

        </Grid> </div>
    </div> 

        </section>

<Grid container className="section-precio-producto" data-aos="fade-down">
    <Grid item xs={12}>
        <h3 className="precio-producto-seleccion"> Tipo de Cotización Arriendo o Venta</h3>
    </Grid>
    <Grid item xs={12}>
<div className="botones-producto-seleccion">
<a href="/Contacto">
    <button  className="cotizar-seleccion-producto"  onClick={cotizarProductos} >COTIZAR</button>
    </a>
    <Link to="/cart">
                <button className="cotizar-seleccion-producto" onClick={handleAgregarAlCarrito}>AGREGAR</button>
              </Link>
           
</div>

    </Grid>
</Grid>
    </section>
    </Container>
    </>
    
)
}