
import ReactPlayer from 'react-player';
import { Grid, Container } from "@mui/material";
import "../../style/estructura racks/estrucrack.css"

import React from "react";


export default function VentajaRacks ( { rack} ) {
    


    return(
        < Container  maxWidth="lg" > 
        <section>
            <Grid container className="ventajas-rack-estructura">
                
                <Grid item xs={1} sm={1} className="separador-racks-principal"> 
                <div className="separador-linea-rack"></div>
                </Grid>
                <Grid item xs={11} sm={11}> 
                <div className="contenedor-descripcion-rack">
                <h4 className="titulo-ventaja-estructura">descripción</h4>   
                
                <h2 className="descripcion-rack-final-estrcutura">
                {rack.descripcion_2}
                </h2>
                </div>
                
                </Grid>
            </Grid>
        </section>
        <section className="video-racks-estructura">
            <div className="contenedor-video-racks">
            <ReactPlayer url={rack.video} className="videoproducto" width="80%" height="100%" style={{ maxHeight: '70vh' }}   loop controls/>
            </div>
        </section>
        </Container>
    )
}