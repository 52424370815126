import React from "react";
import ContactoPrincipal from "../components/contacto/contacto-principal";
import { Helmet } from "react-helmet";


export default function Contacto (){
    return(
        <>
         <Helmet>
  <title>STACKER | Cotiza con nosotros.</title>
  <meta
    name="description"
    property="og:description"
    content="Arriendo y venta  de la máquina a la modalidad que mas te acomode cotizando con nosotros, lleva tus operaciones al siguiente nivel."
  />
  <meta
    name="keywords"
    property="og:keywords"
    content="grúas horquillas, transpaletas, apiladores, pasillo angosto, reach stacker, alzahombres, order picker, racks."/>
  <meta property="og:title" content="STACKER | Cotiza con nosotros." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://stacker.cl" />
        <meta property="og:url" content="https://stacker.alerama.cl/Productos" />
        <meta property="og:url" content="https://stacker.alerama.cl/Catalogo" />
  <meta name="author" content="STACKER" />
        <meta name="robots" content="index,follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="publisher" content="stacker" />
        <link rel="canonical" href="http://www.stacker.cl/contacto" />
        
</Helmet>
        <ContactoPrincipal/>
        </>
    )
}