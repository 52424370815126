
import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { TbFaceIdError } from "react-icons/tb";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {   Navigation, Autoplay } from "swiper/modules";
import LoaderModal from "../otroscomponentes/louding";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import 'aos/dist/aos.css'; 

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/swiper-bundle.css';



  export default function Filtrar() {
    const [filtros, setFiltros] = useState({
      categoria: null,
      tipoBateria: '',
      capacidad: '',
      altura: '',
      capacidadBateria: ''
    });
  
    const [isFiltroEnabled, setIsFiltroEnabled] = useState({
      tipoBateria: false,
      capacidad: false,
      altura: false,
      capacidadBateria: false
    });
  
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [mostrarFiltros, setMostrarFiltros] = useState(true);
    const [productos, setProductos] = useState([]);
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [tiposBateria, setTiposBateria] = useState([]);
    const [capacidades, setCapacidades] = useState([]);
    const [alturas, setAlturas] = useState([]);
    const [capacidadBaterias, setCapacidadBaterias] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadings, setIsLoadings] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const location = useLocation();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const categoriaSeleccionadaURL = queryParams.get('categoria');
  
      if (categoriaSeleccionadaURL) {
        setCategoriaSeleccionada(categoriaSeleccionadaURL);
        setFiltros(prev => ({ ...prev, categoria: categoriaSeleccionadaURL }));
      }
    }, [location]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const responseProductos = await axios.get(`${backendURL}/api/get/all/productos`);
          const responseCategorias = await axios.get(`${backendURL}/api/get/all/categorias`);
  
          if (responseProductos.status === 200 && responseCategorias.status === 200) {
            const productosData = responseProductos.data.reverse();
            setProductos(productosData);
            setCategorias(responseCategorias.data);
            setIsLoading(false);
          } else {
            console.error("Error al obtener datos de productos o categorías");
          }
        } catch (error) {
          console.error("Error de red", error);
        }
      };
  
      fetchData();
    }, [backendURL]);

  
    useEffect(() => {
      aplicarFiltros(productos); // Aplicar filtros al cambiar cualquier filtro relevante
    }, [filtros, productos]); // Dependencia filtros y productos para ejecutar al cambiar
  
    const actualizarFiltrosRelacionados = (productosFiltrados) => {
      setTiposBateria([...new Set(productosFiltrados.map(producto => producto.bateria))]);
      setCapacidades([...new Set(productosFiltrados.map(producto => producto.capacidad))]);
      setAlturas([...new Set(productosFiltrados.map(producto => producto.altura))]);
      setCapacidadBaterias([...new Set(productosFiltrados.map(producto => producto.capacidad_bateria))]);
    };
  
    useEffect(() => {
      // Aplicar filtros al cambiar la categoría seleccionada
      if (categoriaSeleccionada !== null) {
        handleFiltroChange('categoria', categoriaSeleccionada);
      }
    }, [categoriaSeleccionada]);
    
  
    const limpiarFiltros = () => {
      setFiltros({
        categoria: null,
        tipoBateria: '',
        capacidad: '',
        altura: '',
        capacidadBateria: ''
      });
  
      setIsFiltroEnabled({
        tipoBateria: false,
        capacidad: false,
        altura: false,
        capacidadBateria: false
      });
      setCategoriaSeleccionada('')
      setProductosFiltrados(productos); 
    };
  
    const handleFiltroChange = (filtro, valor) => {
      switch (filtro) {
        case 'categoria':
          setCategoriaSeleccionada(valor);
          setFiltros(prev => ({ ...prev, categoria: valor }));
          setIsFiltroEnabled(prev => ({ ...prev, tipoBateria: true }));
          setFiltros({
            categoria: valor,
            tipoBateria: '',
            capacidad: '',
            altura: '',
            capacidadBateria: ''
          });
          setIsFiltroEnabled({
            tipoBateria: true,
            capacidad: false,
            altura: false,
            capacidadBateria: false
          });
          
          break;
        case 'tipoBateria':
          console.log('Tipo de Batería seleccionado:', valor);
          setFiltros(prev => ({ ...prev, tipoBateria: valor }));
          setIsFiltroEnabled(prev => ({ ...prev, capacidad: true }));
          break;
        case 'capacidad':
          setFiltros(prev => ({ ...prev, capacidad: valor }));
          setIsFiltroEnabled(prev => ({ ...prev, altura: true }));
          break;
        case 'altura':
          setFiltros(prev => ({ ...prev, altura: valor }));
          setIsFiltroEnabled(prev => ({ ...prev, capacidadBateria: true }));
          break;
        case 'capacidadBateria':
          setFiltros(prev => ({ ...prev, capacidadBateria: valor }));
          break;
        default:
          break;
      }
    };

  
    const aplicarFiltros = (productos) => {

      setIsLoadings(true); 

      let productosFiltrados = [...productos];
  
      if (filtros.categoria) {
        productosFiltrados = productosFiltrados.filter(producto => producto.id_categoria.toString() === filtros.categoria.toString());
      }
  
      if (filtros.tipoBateria) {
        productosFiltrados = productosFiltrados.filter(producto => producto.bateria.toLowerCase() === filtros.tipoBateria.toLowerCase());
      }
  
      if (filtros.capacidad) {
        productosFiltrados = productosFiltrados.filter(producto => String(producto.capacidad).toLowerCase() === String(filtros.capacidad).toLowerCase());
      }
  
      if (filtros.altura) {
        productosFiltrados = productosFiltrados.filter(producto => producto.altura.toLowerCase() === filtros.altura.toLowerCase());
      }
  
      if (filtros.capacidadBateria) {
        productosFiltrados = productosFiltrados.filter(producto => producto.capacidad_bateria.toLowerCase() === filtros.capacidadBateria.toLowerCase());
      }
  
      setProductosFiltrados(productosFiltrados);
      actualizarFiltrosRelacionados(productosFiltrados);
      setIsLoadings(false); 
    };
  
    const toggleFiltros = () => {
      setMostrarFiltros(!mostrarFiltros);
    };
    

   
    /* paginacion */
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const productosActuales = productosFiltrados.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPagesFiltered = Math.ceil(productosFiltrados.length / itemsPerPage);
    const handlePageClick = (page) => {
      setCurrentPage(page);
    };
  const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const numElementosPorFila = mostrarFiltros ? 4 : 3;
  const [buttonsToShow, setButtonsToShow] = useState(3);
  
    const filtroGridProps = mostrarFiltros ? "show-filters" : "hide-filters";
    const productosGridProps = mostrarFiltros
      ? { xs: 12, sm: 6, md: 9 }
      : { xs: 12, sm: 6, md: 12 };
  
  
    useEffect(() => {
      updateButtonsToShow();
    }, [currentPage, productos.length]);
  
    const updateButtonsToShow = () => {
      const totalPages = Math.ceil(productos.length / itemsPerPage);
      let newButtonsToShow = 3;
  
      if (totalPages <= 3) {
        newButtonsToShow = totalPages;
      } else if (currentPage <= 2) {
        newButtonsToShow = 3;
      } else if (currentPage + 1 >= totalPages) {
        newButtonsToShow = totalPages;
      } else {
        newButtonsToShow = currentPage + 1;
      }
  
      setButtonsToShow(newButtonsToShow);
    };
  
    if (isLoadings) {
      return (
        <Grid container justifyContent="center" alignItems="center" sx={{display:"flex", alignItems:"center", justifyContent:"center"}} >
          <CircularProgress sx={{ color:"#115c67 "}} /> 
         
        </Grid>
      );
    }
  
   
    return (
      <>
       {isLoading ? (
       <LoaderModal />
      ) : (
        <>
        <Container maxWidth="xl" >
        
        <section className="categoria-prducto">
        
    <Swiper
            slidesPerView={4}
            spaceBetween={1}
            breakpoints={{
              240: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              1000: { slidesPerView: 4, spaceBetween: 1 },
              1100: {
                slidesPerView: 5,
                spaceBetween: 2,
              },
              1566: {
                slidesPerView: 6,
                spaceBetween: 1,
              },
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            navigation={true}
            
            modules={[ Autoplay, Navigation]}
            className="producto-servicios"
          >
            {categorias.map((categoria) => (
            <SwiperSlide key={categoria.id} style={{ 
              
            display:"flex", alignItems:"center", justifyContent:"center"
          }} >
              <div style={{padding:"0px 10px"}} data-aos="fade-down">
              <button onClick={() => {
                  handleFiltroChange('categoria', categoria.id)
          setCurrentPage(1); // Reiniciar el paginador al hacer clic en una categoría
        }} className="buttoncategoria">
              <div className="icono-categoria"> 
              {categoria.imagen && (
          <img
          className="maquina-cateogria"
          style={{width:"80px"}}
            src={`${backendURL}/${categoria.imagen}`}
            alt={categoria.seo || categoria.nombre}
            title={categoria.seo || categoria.nombre}
            />
            
            )} 
            
            
            </div>
            <div className="contienetitulocategoriapropductopagina">
               <strong>
                 <h2> {categoria.descripcion}</h2>
                </strong>
                </div>
            
            </button>
          </div> </SwiperSlide>         
      ))}</Swiper>
   
   
  </section>
        <section className="principal filtrado" data-aos="fade-down">
        <div className="contenedor filtrar">
    <div className="contenedor-filtrado">
      <label className="burger" htmlFor="burger"  > 
         <input type="checkbox" id="burger" onClick={toggleFiltros} />
        <p>FILTRAR</p>
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
  </div>
        </section>
  
        <section className="section-productos">
          <Grid container  sx={{display:"flex"}} className="productosfiltrados">
            <Grid item  className={`transition-container ${filtroGridProps }`}>
         
            <div className={`opciones-filtrado-container ${mostrarFiltros ? 'visible' : 'oculto'}`}>
  
      <ul className="opciones-filtrado">
        <li>
        <label>
    <p className="titulo-select-producto">CATEGORÍA</p>
    <select
    className="select-producto"
    value={categoriaSeleccionada}
    onChange={(e) => handleFiltroChange('categoria', e.target.value)}
  >
    <option value="" >Seleccionar</option>
    {categorias.map((categoria) => (
      <option key={categoria.id} value={categoria.id}>
        {categoria.descripcion}
      </option>
    ))}
  </select>
  </label>
        </li>
  
        <li>
        
  
        </li>
      
     
        <li>
  <label>
    <p className="titulo-select-producto">Energía</p>
    <select
      className="select-producto"
      value={filtros.tipoBateria}
      onChange={(e) => handleFiltroChange('tipoBateria', e.target.value)}
     
    >
      <option value="">Seleccionar</option>
      {tiposBateria.map((tipoBateria, index) => (
                    <option key={index} value={tipoBateria}>
                      {tipoBateria}
                    </option>
                  ))}
    </select>
  </label>
</li>
  
  
  <li>
    <div>
      <label>
        <p className="titulo-select-producto">Capacidad</p>
        <select
          className="select-producto"
          value={filtros.capacidad}
              onChange={(e) => handleFiltroChange('capacidad', e.target.value)}
           
        >
          <option value="">Seleccionar</option>
          {capacidades.map((capacidad, index) => (
                    <option key={index} value={capacidad}>
                      {capacidad}
                    </option>
                  ))}
        </select>
      </label>
    </div>
  </li>
  
  <li>
    <div>
      <label>
        <p className="titulo-select-producto">Altura</p>
        <select
          className="select-producto"
          value={filtros.altura}
              onChange={(e) => handleFiltroChange('altura', e.target.value)}
            
        >
          <option value="" >Seleccionar</option>
          {alturas.map((altura, index) => (
                    <option key={index} value={altura}>
                      {altura}
                    </option>
                  ))}
        </select>
      </label>
    </div>
  </li>
    
  <li>
    <label>
      <p className="titulo-select-producto">Capacidad Energía</p>
     
      <select
        className="select-producto"
        value={filtros.capacidadBateria}
        onChange={(e) => handleFiltroChange('capacidadBateria', e.target.value)}
      
       >
        
        <option value="">Selecionar</option>
        {capacidadBaterias.map((capacidadBateria, index) => (
                    <option key={index} value={capacidadBateria}>
                      {capacidadBateria}
                    </option>
                  ))}
      </select>
      
    </label>
  </li>  
  
   <li>
        <button className="limpiar-filtros"  onClick={limpiarFiltros} >
          Limpiar filtros
        </button>
      </li>
      </ul>
   
    </div>
            </Grid>

          <Grid item  {...productosGridProps}>
          {productosFiltrados.length === 0 ? (
    <div className="no-products-message">
      <TbFaceIdError />
      <p>No se encontraron productos que coincidan con los filtros seleccionados.</p>
      <button className="limpiar-filtros"  onClick={limpiarFiltros} >
          Productos
        </button>

    </div>
  ) : (


          <Grid container sx={{  '@media (max-width: 600px)': {flexBasis:"70%"},
          
          }} >
         
          {productosFiltrados.slice(startIndex, endIndex).map((producto, index) => (
                <Grid key={index} item xs={12} sm={12} md={numElementosPorFila} >
             <a href={`/Producto/${producto.id}`}  title="producto" onClick={() => window.scrollTo(0, 0)}>
  <div className="card-producto">
    <div className="container-imagen-producto">
      {producto.imagen && (
        <img
          src={`${backendURL}/${producto.imagen}`}
          alt={producto.seo || producto.nombre}
          title={producto.seo || producto.nombre}
          className="maquinaselecion"
        />
      )}
    </div>
    <div className="informacion-productos">
      <h2 className="titulo-producto">{producto.nombre} {producto.bateria}</h2>
      <hr className="separador-producto" />
      <h3 className="titulodescripcion">Toneladas {producto.capacidad} y Altura {producto.altura} </h3>
     <div className="fondoarriendo"> <span className="informacion-producto">Arriendo o Venta</span></div>
      <button className="cotizar-producto">COTIZAR </button>
    </div>
  </div>
</a>

                </Grid>
                
              ))}
            </Grid>
            )} 
          </Grid>
        
        </Grid>
      </section>
      <div className="pagination-buttons">
  <button
  onClick={() => {
    handlePageClick(currentPage - 1);
    const windowHeight = window.innerHeight;
    window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en la página anterior
  }}
    className="prev-next"
    disabled={currentPage === 1}
  >
    <FaAngleLeft />
  </button>

  {Array.from({ length: buttonsToShow }, (_, i) => {
  const page = currentPage - 1 + i;
  // Si el número de página es mayor que el último conjunto de páginas disponibles, no mostrar el botón
  if (page + 1 > totalPagesFiltered || page + 1 > currentPage + 3) {
    return null;
  }

  return (
    <button
      key={page + 1}
      onClick={() => {
        handlePageClick(page + 1);
        const windowHeight = window.innerHeight;
        window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en una página específica
      }}
      className={currentPage === page + 1 ? 'active' : ''}
    >
      {page + 1}
    </button>
  );
})}

<button
   onClick={() => {
    handlePageClick(currentPage + 1);
    const windowHeight = window.innerHeight;
    window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en la página siguiente
  }}
  className="prev-next"
  disabled={currentPage === totalPagesFiltered}
>
  <FaAngleRight />
</button>
</div>
</Container>
</> 
 )}
    </>
  );
} 
 
