import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(sessionStorage.getItem('token'));
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const logout = async () => {
        try {
            const response = await fetch(`${backendURL}/api/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setToken(null);
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('isAdmin');
            window.location.href = '/stacker';
        } catch (error) {
            console.error('Error logging out', error);
        }
    };

    return (
        <AuthContext.Provider value={{ token, setToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
  