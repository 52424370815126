import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

/* componente */
import { Navbar } from './components/navbar/navigation';
import { Navbaradmin } from "./components/login/navbar-login";
import { Footer } from './components/footter/footer';
import Home from './page/home';
 import Contacto from './page/contacto';
import Servicios from './page/servicios';
import Rack from './page/racks';
import Productos from './page/productos';
import Productoid from './page/producto';
import Carro from './components/otroscomponentes/carrito'; 
 import { CarritoContextoProvider } from './components/context/cotizar-final';
import { CarritoProvider } from './components/context/carrito-cotizar';
import { AuthProvider, useAuth } from "./components/context/tokenadmin";
import RackPage from './page/rack'; 

/* administrador */
import { Login } from './page/login';
import { Administrador } from "./page/admnistrador";
import { Portadaadminhome } from "./components/administrador/portadahome";
import { Cardadminhome } from "./components/administrador/cardhome";
import { Cardtodosadminhome } from "./components/administrador/cardtodoshome";
import { Portadamaquinaria } from "./components/administrador/portadamaquinaria";
import { Portadaracks } from "./components/administrador/potadaracks";
import { Productosmaquinariaadmin } from "./components/administrador/productosmaquinaria";
import { Productosracksadmin } from "./components/administrador/racksproductosadmin";

ReactGA.initialize('G-K81HDJE12R'); 

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

const LayoutContent = ({ setShowNavbarFooter, showNavbarFooter }) => {
  const location = useLocation();
  const { token } = useAuth(); 

  const isAdminRoute = location.pathname.startsWith('/adminstacker');

  useEffect(() => {
    const isAdminRoute = location.pathname.startsWith('/adminstacker');
    const isLoginRoute = location.pathname === '/stacker';

    if (isAdminRoute || isLoginRoute) {
      setShowNavbarFooter(false);
    } else {
      setShowNavbarFooter(true);
    }
  }, [location, setShowNavbarFooter]);

  return (
    <>
      <TrackPageView />
      {token ? <Navbaradmin /> : showNavbarFooter && <Navbar />}
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Contacto />} path="/contacto" />
        <Route element={<Servicios />} path="/servicios" />
        <Route element={<Rack />} path="/catalogo" />
        <Route element={<Productos />} path="/productos" />
        <Route element={<Carro />} path="/cart" />
        <Route element={<Login setShowNavbarFooter={setShowNavbarFooter} />} path="/stacker" />
        <Route element={<Administrador setShowNavbarFooter={setShowNavbarFooter} />} path="/adminstacker" />
        <Route element={<Portadaadminhome setShowNavbarFooter={setShowNavbarFooter} />} path="/portadaadminhome" />
        <Route element={<Cardadminhome setShowNavbarFooter={setShowNavbarFooter} />} path="/cardadminhome" />
        <Route element={<Cardtodosadminhome setShowNavbarFooter={setShowNavbarFooter} />} path="/cardhome" />
        <Route element={<Portadamaquinaria setShowNavbarFooter={setShowNavbarFooter} />} path="/portadamaquinaria" />
        <Route element={<Portadaracks setShowNavbarFooter={setShowNavbarFooter} />} path="/portadarackadmin" />
        <Route element={<Productosmaquinariaadmin   setShowNavbarFooter={setShowNavbarFooter} />} path="/productomaqunariaadmi" />
        <Route element={<Productosracksadmin   setShowNavbarFooter={setShowNavbarFooter} />} path="/productoracksadmi" />
        <Route path="/producto/:id" element={<Productoid />} />
        <Route path="/rack/:id" element={<RackPage />} />
        <Route element={<Contacto/>} path="*" />
      </Routes>

      {!token && showNavbarFooter && <Footer />}
    </>
  );
};

const Layout = () => {
  const [showNavbarFooter, setShowNavbarFooter] = useState(true);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-PCLFLK4J' });
  }, []);

  const basename = process.env.BASENAME || "";

  return (
    <AuthProvider>
    <CarritoProvider>
      <CarritoContextoProvider>
        <BrowserRouter basename={basename}>
          <LayoutContent setShowNavbarFooter={setShowNavbarFooter} showNavbarFooter={showNavbarFooter} />
        </BrowserRouter>
      </CarritoContextoProvider>
    </CarritoProvider>
    </AuthProvider>
  );
};

export default Layout;