import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../../style/login/administrador.css";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { TfiGallery } from "react-icons/tfi";
import { TbPhotoCirclePlus } from "react-icons/tb";
import { TbPhotoCircle } from "react-icons/tb";
import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal
} from "@mui/material";


export const Portadaadminhome = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [portadasData, setPortadasData] = useState([]);
    const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isModalOpensgaleria, setIsModalOpensgaleria] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [galeriaData, setGaleriaData] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

  

    const fetchPortadas = async () => {
          try {
            const response = await axios.get(`${backendURL}/api/get/all/portadas`);
            setPortadasData(response.data);
            if (response.status === 200) {
              if (response.data.length > 0) {
                const portadaGaleriaResponse = await axios.get(`${backendURL}/api/get/all/portadasgaleria?id=${response.data[0].id}`);
                setPortadasGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
                setGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
               
              }
            } else {
              console.error('Error al obtener los datos de portadas:', response);
            }
          } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
          }
        };

    
       useEffect(() => {
        fetchPortadas();
    }, []);


    useEffect(() => {
        setShowNavbarFooter(false);

        // Verifica si el token no existe y redirige
        if (!token) {
            logout(); 
            navigate('/stacker'); // o la ruta que desees
        }

    }, [setShowNavbarFooter, token, logout, navigate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
  
    const handleOpenGaleria = (categoria) => {
        setGaleriaData(categoria); // Set the selected portada
        setIsModalOpensgaleria(true);
    };
    const handleClosegaleria = () => {
        setIsModalOpensgaleria(false);
    };
    const handleOpenModals = (portada) => {
        setPortadasGaleriaData(portada); // Set the selected portada
        setIsModalOpens(true);
    };

    const handleCloseModals = () => {
        setIsModalOpens(false);
    };

    const handleDelete = async (portadaId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`${backendURL}/api/deleteAdmin/${portadaId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                fetchPortadas();  // Refresh the portadas list after deletion
            } else {
                console.error('Error al eliminar la portada:', response);
            }
        } catch (error) {
            console.error('Error al eliminar la portada:', error);
        } finally {
            setIsLoading(false);  // Stop loading indicator
        }
    };

    return(
         <>
      <Container maxWidth="lg">
        <Box>
            <h2 className='tituloadministrador'> Portada Inicio Stacker</h2>
        </Box>
        <Box >
            <button  onClick={handleOpenModal} className='buttonaccionedit'> <MdOutlineCreateNewFolder /> <span>Crear</span></button> 
       
        </Box>
        <Box>
        <TableContainer component={Paper}>
                     <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        /* count={rows.length} */
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />    <Table aria-label="simple table"> 
                   
                        <TableHead>
                            <TableRow>
                                <TableCell>Titulo</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Link</TableCell>
                                <TableCell>Imagen portada web</TableCell>
                                <TableCell>Imagen portada  celular</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {portadasData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((portada) => (
                                <TableRow key={portada.id}>
                                    <TableCell>{portada.titulo}</TableCell>
                                    <TableCell>{portada.descripcion}</TableCell>
                                    <TableCell>{portada.url}</TableCell>
                                    <TableCell>
                                        <img src={`${backendURL}/${portada.imagen_web}`} alt={portada.alt_seo} style={{ maxWidth: '100px' }} />
                                    </TableCell>
                                    <TableCell>
                                        <img src={`${backendURL}/${portada.imagen_responsive}`} alt={portada.alt_seo} style={{ maxWidth: '100px' }} />
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                        <button className='buttonaccionedit'  onClick={() => handleOpenGaleria(portada)} >  <TfiGallery /><span>Galeria</span></button> 
                                        <button className='buttonaccionedit' onClick={() => handleOpenModals(portada)}> <FiEdit /> <span>Editar</span></button>
                                            <button className='buttonacciondelete' onClick={() => handleDelete(portada.id)} disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                                </button>
                                        
                                        </div>
                                        </TableCell>
                                </TableRow>
                         ))}
                        </TableBody>
                    </Table>
                   
                </TableContainer>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "0%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModal}><IoClose /></div>
                <CrearPortada handleClose={handleCloseModal}  refreshPortadas={fetchPortadas} /> </Box>
                
            </Modal>

            <Modal open={isModalOpens} onClose={handleCloseModals}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "0%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModals}><IoClose /></div>
                <EditarPortada
                 handleClose={handleCloseModals}
                   refreshPortadas={fetchPortadas} 
                selectedPortada={portadasGaleriaData}/> 
                </Box>
                
            </Modal>

            <Modal open={isModalOpensgaleria} onClose={handleClosegaleria}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "5%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleClosegaleria}><IoClose /></div>
                <Galeriaproducto
                 handleClose={handleClosegaleria}
                   refreshPortadas={fetchPortadas} 
                   selectedPortada={galeriaData}/>  </Box>
                
            </Modal>
            
        
      </Container>
    
    
    </>)
}



// Componente para crear la portada
const CrearPortada = ({ handleClose, refreshPortadas }) => {
    const { token} = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        url: '',
        imagen_web: null,
        imagen_responsive: null,
        alt_seo: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen_web' || name === 'imagen_responsive') {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.titulo) errors.titulo = 'El título es obligatorio';
        if (!formData.url) errors.url = 'El link es obligatorio';
        if (!formData.descripcion) errors.descripcion = 'La descripción es obligatoria';
        if (!formData.imagen_web) errors.imagen_web = 'La imagen es obligatoria';
        if (!formData.imagen_responsive) errors.imagen_responsive = 'la imagen es obligatoria';
        if (!formData.alt_seo) errors.alt_seo = 'El texto SEO es obligatorio';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
       
       e.preventDefault();
        if (!validateForm()) {
            setErrorModalOpen(true);
            return;
        }

        setIsLoading(true);
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
    
        try {
            const response = await axios.post(`${backendURL}/api/storePortadaAdmin`, formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${token}`
                },
            });
            if (response.data.success) {
                setSuccessModalOpen(true);
                refreshPortadas(); 
            } else {
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorModalOpen(true);
            console.error('Error creating portada:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    
   

    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Crear Nueva portada inicio</Typography>
           
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Título de la portada"
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleChange}
                    error={!!formErrors.titulo}
                    helperText={formErrors.titulo}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Descripción"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    error={!!formErrors.descripcion}
                    helperText={formErrors.descripcion}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="URL"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    error={!!formErrors.url}
                    helperText={formErrors.url}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web" sx={{ top: "-7px" }}>
                        Imagen portada web
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_web"
                        id="imagen_web"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!formErrors.imagen_web}
                        helperText={formErrors.imagen_web}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_responsive" sx={{ top: "-7px" }}>
                        Imagen portada celular
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_responsive"
                        id="imagen_responsive"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={!!formErrors.imagen_responsive}
                        helperText={formErrors.imagen_responsive}
                    />
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Texto SEO"
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                    error={!!formErrors.alt_seo}
                    helperText={formErrors.alt_seo}
                />
                <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
                     {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>

            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
                <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">¡Categoria creada correctamente!</Typography>
                    <button className='buttonaccionedit' onClick={() => setSuccessModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>

            {/* Error Modal */}
            <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
                <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">Ocurrió un error. Inténtalo de nuevo.</Typography>
                    <button className='buttonaccionedit' onClick={() => setErrorModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>
        </Box>
    );
};

const EditarPortada = ({ handleClose, refreshPortadas, selectedPortada }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        url: '',
        imagen_web: null,
        imagen_responsive: null,
        alt_seo: ''
    });

    useEffect(() => {
        if (selectedPortada) {
            setFormData({
                titulo: selectedPortada.titulo,
                descripcion: selectedPortada.descripcion,
                url: selectedPortada.url,
                alt_seo: selectedPortada.alt_seo,
                imagen_web: selectedPortada.imagen_web,
                imagen_responsive: selectedPortada.imagen_responsive
            });
        }
    }, [selectedPortada]);

  
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen_web' || name === 'imagen_responsive') {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('titulo', formData.titulo);
        formDataToSend.append('descripcion', formData.descripcion);
        formDataToSend.append('url', formData.url);
        formDataToSend.append('alt_seo', formData.alt_seo);
        
        if (formData.imagen_web) {
            formDataToSend.append('imagen_web', formData.imagen_web);
        }
        
        if (formData.imagen_responsive) {
            formDataToSend.append('imagen_responsive', formData.imagen_responsive);
        }

        // Verifica los datos que se están enviando
        for (let [key, value] of formDataToSend.entries()) {
            console.log(key, value);
        }

        const response = await axios.post(`${backendURL}/api/editPortadasAdmin/${selectedPortada.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            refreshPortadas();
            handleClose();
        } else {
            console.error('Error al actualizar la portada:', response);
        }
    } catch (error) {
        console.error('Error al actualizar la portada:', error);
    }
    finally{
        setIsLoading(false);
        handleClose();
    }
};


    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Editar Portada inicio</Typography>
            {/* Formulario de edición */}
            <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        Título Portada
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleChange}
                />
                </FormControl>
                   <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        Descripción
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />
                </FormControl>
                 <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        Url
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        Imagen portada web
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_web"
                        id="imagen_web"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {formData.imagen_web && (
                        <img
                            src={`${backendURL}/${formData.imagen_web}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_responsive">
                        Imagen portada celular
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_responsive"
                        id="imagen_responsive"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {formData.imagen_responsive && (
                        <img
                            src={`${backendURL}/${formData.imagen_responsive}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                        SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                />
                </FormControl>
                <button className='buttonaccionedit' type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>
        </Box>
    );
};

const Galeriaproducto = ({ selectedPortada }) => {
    const { token } = useAuth();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [galeriaData, setGaleriaData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [titulo, setTitulo] = useState('')
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const fetchGaleriaData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/get/all/portadasgaleria`);
            setGaleriaData(response.data);
           
        } catch (error) {
            console.error('Error fetching galeria data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchGaleriaData();
      }, []);
    
    
      // Filtrar las imágenes por id_portada
      const imagenesFiltradas = galeriaData.filter(img => img.id_portada ==  selectedPortada.id);

    useEffect(() => {
        if (selectedPortada) {
            fetchGaleriaData();
        }
    }, [selectedPortada]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleShowForm = () => {
        setShowForm(!showForm);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setNewImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTituloChange = (event) => {
        setTitulo(event.target.value);
    };

    const handleSubmit = async () => {
        if (newImage && selectedPortada && titulo) {
            const formData = new FormData();
            formData.append('imagen_web', newImage);
            formData.append('id_portada', selectedPortada?.id);
            formData.append('titulo', titulo); 

            try {
                await axios.post(`${backendURL}/api/storeGaleriaPortadasapi`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                fetchGaleriaData();
                setShowForm(false);
                setNewImage(null);
                setImagePreview('');
                setTitulo(''); 
            } catch (error) {
                console.error('Error adding new image:', error);
            }
        }
    };

    const handleDelete = async (id) => {
       
        try {
            await axios.delete(`${backendURL}/api/deleteGaleriaPortadaapi/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            fetchGaleriaData();
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    return (
        <div style={{ marginTop: "100px" }}>
            <Typography variant="h6" sx={{display:"flex", alignItems:"center", justifyContent:"center"}}> <TbPhotoCircle style={{ fontSize:"2rem"}} /> Galeria Portada de inicio {selectedPortada?.titulo}</Typography>

            <Box>
                <button className='buttonaccionedit' style={{width:'200px'}}onClick={handleShowForm}>
                <TfiGallery/> <span>Crear imagen galería</span>
                </button>
            </Box>
            {showForm && (
                <Box marginTop={2}>
                    <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web">
                      Titulo
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="titulo"
                    value={titulo}
                    onChange={handleTituloChange} 
                />
                 </FormControl>
                     <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen_web" sx={{ top: "-7px" }}>
                      Agregar Imagen
                    </InputLabel>
                    <TextField
                        type="file"
                        name="img"
                        id="img"
                        onChange={handleImageChange}
                        InputLabelProps={{ shrink: true }}  
                    /> 
                    {imagePreview && (
                        <Box marginTop={1}>
                            <img src={imagePreview} alt="Vista previa" width="100" />
                        </Box>
                    )}
                </FormControl>
                   
                   
                   
                    <button className='buttonaccionedit' onClick={handleSubmit} style={{ marginTop: '10px' }}>
                    <TbPhotoCirclePlus /> Subir Imagen
                    </button>
                </Box>
            )}

            <TableContainer component={Paper}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={galeriaData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Portada</TableCell>
                            <TableCell>Titulo</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">Cargando...</TableCell>
                            </TableRow>
                        ) : imagenesFiltradas.length > 0 ? (
                            imagenesFiltradas
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{selectedPortada?.titulo}</TableCell>
                                    <TableCell>
                                        {item.titulo}
                                    </TableCell>
                                    <TableCell>
                                        <img src={`${backendURL}/${item.imagen_web}`} alt="stacker" width="100" />
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            
                                            <button 
                                                className='buttonacciondelete'
                                                onClick={() => handleDelete(item.id)}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                            </button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No hay datos disponibles</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};