import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import "../../style/home/home.css";
import Lineas from "../../img/home/contactohome/lineas.png";

export default function ConoceMaquinarias() {

  return (

    <section style={{fontSize:"0.1rem", height: '0px', position: 'absolute', top: '0', left: '0' }}>
      <h2 style={{ color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute', fontSize:"0.1rem" }}>Grúa Horquilla</h2>
      <h2 style={{ color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute',fontSize:"0.1rem" }}>
      contamos  con una gran variedad de grúa horquilla  de diferentes <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
      marcar y racks capacidades ,
        su tipo  de energia que es grúa horquilla electrica, otro tipo seria grúa horquilla diesel ,
        tambien contamos con  montacargas manuales ademas de montacargas electricos, asi mismo montacargas elevador y 
         montacargas elevador vertical del tipo  montacargas toyota que tmabien  <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
         la encontraran  grúa horquilla toyota, 
         siendo asi una grúa horquilla precio bueno siendo una  grúa horquilla usada y creando grúa horquilla yale, a su vez  
         grúa linde cuentan con una grna capacidad  grúa horquilla 16 toneladas,  y grúa horquilla 5 toneladas,  <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
         para grúa horquilla 4x4, 
          las grúa horquilla 3 toneladas,  conteniendo <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
          tmabien grúa horquilla 7 toneladas ,  por ultimo grúa horquilla 10 toneladas.
           otro de nuestros tipos de grúa horquilla  grúa horquilla heli o tambien  llamada grúas heli que tmabien se puede considerar  
             carretilla electrica, o tambien   carretillas caterpillar o conocidas mas bien    carretilla cat llamandose grúa horquilla jungheinrich o 
             conocidamente  grúa horquilla hyster que cuentan con una grúa horquilla con mordaza y se conoce por que son grúa horquilla alto tonelaje</h2>

      <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Racks</h3>
      <h2 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
      racks de stscker son uno de nuestros producto estrella para la  gran cntidad de racks que contamos
       en msntener buenos producto de racks nuestra variedad  racks puede ver nuestros dintistos tipos  racks 
       desde nuestra pagina por la seccion de racks donde encontrara  racks mas aporpiado a su empresa que necesita tener racks, 
       ten un racks a tu medida y preferneica de racks que cuenta stacker.
       la categoria  racks  son un total de diez racks o mas racks que contiene stacker  servicio  racks. 
       recuerda selecionar el racks de tu preferiencia que tenga las capacidades que mas uqieras racks igual a racks.
       racks de almacenamiento son esenciales en cualquier bodega organizada.
        Estos racks permiten una mejor distribución de productos, facilitando el acceso a ellos
        . Además, racks industriales están diseñados para soportar cargas pesadas, 
        lo que hace ideales para su uso en almacenes grandes.
         La instalación de racks puede aumentar significativamente la eficiencia del espacio. 
         Al elegir racks adecuados, se pueden evitar accidentes y mejorar la seguridad. En resumen,
          invertir en buenos racks es crucial para cualquier operación de almacenamiento.

En el sector de la logística, racks juegan un papel fundamental. 
Los racks ajustables permiten adaptarse a diferentes tamaños de productos. 
Es importante considerar la capacidad de carga  racks para evitar sobrecargas. 
Los racks móviles ofrecen flexibilidad en el almacenamiento, permitiendo reconfiguraciones rápidas. 
Además, racks metálicos son preferidos por su durabilidad. Mantener racks en buen estado garantiza una operación eficiente y segura.

Para la exhibición de productos en tiendas, racks son una herramienta imprescindible. 
Los racks exhibidores permiten mostrar productos de manera atractiva.
 Existen racks específicos para diferentes tipos de productos,  racks para ropa o racks para herramientas.
  Al usar racks adecuados, se puede optimizar el espacio de venta. Además, racks de madera ofrecen una apariencia más estética. 
  Seleccionar racks correctos puede influir en las ventas y la experiencia del cliente.

Los racks para servidores son esenciales en centros de datos. Estos racks están diseñados para alojar equipos de TI de manera segura y organizada.
 La ventilación adecuada en racks es crucial para mantener la temperatura. racks de 19 pulgadas son el estándar en la industria. Además, 
 racks con puertas de vidrio permiten monitorear equipos sin abrirlos. La gestión de cables en racks ayuda a mantener un entorno limpio y eficiente. 
 Invertir en buenos racks de servidores mejora la infraestructura de TI.

En gimnasios, racks para pesas son fundamentales para el orden y la seguridad. 
Estos racks permiten almacenar pesas de manera organizada. Existen racks específicos para mancuernas y racks para barras.
 racks ajustables permiten cambios rápidos durante entrenamientos. Además, racks de alta resistencia soportan pesas pesadas. 
 Mantener racks en buen estado evita accidentes y prolonga su vida útil. racks adecuados mejoran la experiencia de usuarios en el gimnasio.

En el sector agrícola, 
los racks de cultivo son una solución eficiente para el almacenamiento de plantas. 
Estos racks permiten una disposición vertical, optimizando el espacio. 
Los racks con sistemas de riego integrado facilitan el cuidado de las plantas.
Además, racks modulares pueden adaptarse a diferentes tipos de cultivos.
 Mantener racks limpios y en buen estado es esencial para la salud de las plantas.
  racks agrícolas mejoran la productividad y la gestión del espacio en invernaderos y granjas.
      </h2>

    



      <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Transpaleta</h3>
      <h2  style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
      nuestras transpaletas cuentan con una variedad de energia   transpaleta electrica o  tambien 
       transpalet electrica ademas de tener manejo transpaleta manual y transpaletas manuales que tmabien estan en formato 
        transpaletas electricas y mas pequeño  transpaleta eléctrica y con detalles transpaleta electrica crown pesando manuelmente 
         las transpaleta manual 2500 kg y plus en las transpaleta electrica elevadora que tmabien cuenta con transpaleta hidraulica o tambien
           conocidas   transpaletas hidraulicas acaparando transpaleta manual 1000 kg y tneiendo un nuevo  racks tipo de transpaleta eléctrica
            hombre a bordo,  a su vez de transpaleta crown conteniendo un precio transpaleta manual para  transpaleta con balanza teneidno un peso la 
             transpaleta 2500 kg siendo esta  transpaletas usadas  transpaleta electrica doble  tiene unacarga transpaleta eléctrica 1500 kg siendo venta 
             de transpaletas de la marca  transpaleta eléctrica jungheinrich uno transpaleta manual 3000 kg con un uso  transpaleta 3000 kg contando  la
              transpaleta precio asi mismo de transpaleta hombre a bordo  la transpaleta electrica linde que transpaleta manual 2000 kg o llamado
               transpaleta 2000 kg  o si no transpaleta eléctrica linde con mayor transpaleta con pesa.
      </h2>



      <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Apilador</h3>
      <h2  style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
      nuestro apiladores cuentan con una gran variedad de apiladores  eléctricos,  tambien apiladores reach, o tmabien veras apiladores electrico crow que tmabien por su estilo de  apilador jungheinrich conocida  apilador eléctrico jungheinricho asi mismo apilador linde que es un apilador manual 1000 kg  con una tecnologia de apilador eléctrico linde conociendo la robotica de apilador bt que ucenta con apilador crown doble pantógrafo siendo palidaores manual  apilador manual 1500 kg de jungheinrich apilador siendo finalmente un apilador flexi para apilador electrico hombre a bordo contando  con montacargas manuales y tambien  montacargas electricos   tambien conocido  montacargas elevador que se encuentra en su version  montacargas elevador vertica o tambien llamado carretilla electrica.
      </h2>

      <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Pasillo Angosto</h3>
    

      <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Reach Stacker</h3>
      <h2 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Reach Stacker : El reach stacker es una máquina  <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
      esencial<h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
      en los puertos; con un reach stacker se pueden mover contenedores con facilidad. Los operadores de reach stacker deben estar bien entrenados, ya que manejar un reach stacker requiere habilidad. Muchas empresas invierten en un reach stacker debido a su eficiencia. Además, el mantenimiento del reach stacker es crucial para su rendimiento. Un reach stacker bien cuidado puede durar muchos años. En resumen, el reach stacker es indispensable para la logística moderna. Sin un reach stacker, el manejo de cargas sería más complicado. Cada reach stacker tiene capacidades distintas, y elegir el reach stacker adecuado depende de las necesidades específicas. Con el reach stacker adecuado, la carga y descarga de contenedores se vuelve más eficiente.

Utilizar un reach stacker en el puerto aumenta <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
la productividad. Con un reach stacker, el movimiento de contenedores se agiliza.<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
El reach stacker permite<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
apilar contenedores de manera segura. Además, el reach stacker puede acceder a lugares difíciles. El costo de un reach stacker puede ser alto <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
, pero la inversión vale la pena. Un buen reach stacker reduce el tiempo de trabajo. Operar un reach stacker requiere capacitación especializada. La seguridad es fundamental al usar un reach stacker. Mantener el reach stacker en buen estado es crucial para evitar accidentes. La versatilidad del reach stacker lo hace una herramienta indispensable en la industria portuaria.

El reach stacker se ha vuelto <h2 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2><h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
una herramienta <h2 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2> esencial en la gestión de contenedores. Con un reach stacker, las operaciones portuarias son más eficientes. La capacidad de un reach stacker para apilar contenedores es inigualable. Cada reach stacker está diseñado para manejar diferentes cargas. Es importante seleccionar el reach stacker adecuado para cada tarea. La tecnología del reach stacker ha evolucionado con el tiempo. Gracias al reach stacker, los puertos pueden manejar más carga en menos tiempo. El mantenimiento regular del reach stacker garantiza su funcionamiento óptimo. La seguridad es una prioridad al operar un reach stacker. Invertir en un reach stacker de calidad es una decisión inteligente para cualquier empresa logística.

La eficiencia del reach stacker es incomparable en la industria de la logística. Operar  reach stacker requiere precisión y <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
 cuidado. Con  reach stacker, los contenedores se pueden mover rápida y fácilmente. La durabilidad de  reach stacker depende de <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
 su mantenimiento. Cada reach stacker está construido para soportar cargas pesadas. La tecnología moderna ha mejorado el rendimiento del reach stacker. Elegir el reach stacker correcto es crucial para las operaciones portuarias.  reach stacker bien mantenido puede reducir los costos operativos. La seguridad es esencial cuando se maneja  reach stacker. La versatilidad de  reach stacker lo convierte en a inversión valiosa.

Los avances<h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
en el diseño del reach stacker han mejorado su eficiencia. Utilizar  reach stacker puede <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
aumentar <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
significativamente la productividad. El entrenamiento <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
para manejar  reach stacker es fdamental. La capacidad <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
de carga de  reach stacker varía según el modelo. Mantener  reach stacker en buen estado es esencial para su rendimiento. Con  reach stacker, los contenedores pueden ser apilados de manera segura. La operación segura de  reach stacker requiere atención constante. La inversión en  reach stacker se justifica por sus beneficios. La tecnología en  reach stacker moderno es impresionante. La elección del reach stacker adecuado puede optimizar las operaciones logísticas.

El reach stacker ha revolucionado la<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
gestión de contenedores en los puertos. Con  reach stacker, la eficiencia en la carga y descarga aumenta. <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2> La tecnología de  reach stacker moderno es avanzada. Operar  reach stacker de manera segura es vital. El mantenimiento de un reach stacker asegura su durabilidad. La capacidad de un reach stacker para manejar cargas pesadas es crucial. Cada reach stacker tiene características únicas. Seleccionar el reach stacker adecuado puede mejorar la productividad. La inversión en un reach stacker de calidad es rentable. La seguridad al operar un reach stacker es una prioridad en la industria logística.</h2>
    

      <h3 style={{ fontSize:"0.1rem",color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
    


      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Order Picker</h2>
      <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
    
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla electrica </h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla toyota</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla precio</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla usada </h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla yale</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa linde</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>linde grúas horquillas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla komatsu</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 16 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>racks</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúas yale</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>tipos de grúa horquilla</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>racks</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 5 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas elevador</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 4x4</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla hyster</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla jungheinrich</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 3 ton</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 7 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual 1000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 10 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica 1500 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla heli</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla caterpillar</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúas heli</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla toyota usada</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 30 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> carretilla electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla cat</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla crown</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla jac</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla toyota precio</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla komatsu 3 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla hyundai</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla mitsubishi</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla manitou</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>hyster grúa horquilla</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla diesel</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla hangcha</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 25 toneladas</h2> 
          <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> carretilla cat</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla alto tonelaje</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla con mordaza</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla grande</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla jcb</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla nissan</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla telescopica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla 2 toneladas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>orden picker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúa horquilla todo terreno</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>grúas linde usadas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas manuales</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas electricos</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas elevador</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> montacargas yale</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas elevador vertical</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> montacargas toyota</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> carretilla electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> carretillas caterpillar</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
 
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaletas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpalet electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaletas manuales</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaletas electricas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta electrica crown</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual 2500 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>order picker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for people</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta electrica elevadora</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta hidraulica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaletas hidraulicas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual 1000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>generador de order picker para el </h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for peoplesadasdsa</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica hombre a bordo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta crown</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>precio transpaleta manual</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta con balanza</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta 2500 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>desarrollo order picker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaletas usadas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta electrica doble</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica 1500 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>venta de transpaletas</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica jungheinrich</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual 3000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>maquinaria orider picker construccion</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta 3000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>generador order picker empresa diu</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta precio</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta hombre a bordo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta electrica linde</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta manual 2000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>nuevo order picker  suit</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta 2000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>chergood order picker  for people</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta eléctrica linde</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta con pesa</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>

      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador electrico</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador manual</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador crown</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apiladores electricos</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador reach</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador electrico crown</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador jungheinrich</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apiladora electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador eléctrico jungheinrich</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador linde</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador manual 1000 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>creacion order picker sad</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador eléctrico linde</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador bt</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador crown doble pantógrafo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador doble pantografo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apiladores manuales</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador manual 1500 kg</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>jungheinrich apilador</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador electrico bt</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador electrico doble pantografo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador flexi</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apiladora manual</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>apilador electrico hombre a bordo</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas manuales</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for people</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas electricos</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas elevador</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>montacargas elevador vertical</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>carretilla electrica</h2>
      <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for people</h2>


    </section>
  );
}

export const Texto1 = () => {
      return(
            <>
            <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
                  Grúas horquillas
            </h2>
            <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
            order picker  for people</h2>
            <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Ofrecemos una amplia variedad <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
            de grúas horquillas de todi tipo de grúa horquilla.  Tenemos grúas horquillas eléctricas y también grúas horquillas diésel. Además, disponemos de montacargas manuales  tmabien  montecargas eléctricos, así  montacargas elevadores verticales, incluyendo el montacargas Toyota, que también se conoce  grúa horquilla Toyota.

Nuestra oferta <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
incluye grúas horquillas a precios competitivos, incluyendo opciones de segunda mano. Entre nuestras<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
opciones se encuentran las grúas horquillas Yale, las grúas Linde  <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>con capacidades que van desde 5 toneladas hasta 16 toneladas, y las grúas horquillas 4x4. También contamos con grúas horquillas de 3, 7 y 10 toneladas.

Además, racks ofrecemos grúas horquillas Heli, también conocidas  carretillas eléctricas, y carretillas Caterpillar, conocidas  carretillas Cat. Tenemos grúas horquillas Jungheinrich y Hyster, que incluyen modelos con mordaza y son ideales para trabajos de alto tonelaje. <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2></h3>
           
           
           <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>transpaleta</h2>
           <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>cheer order picker  for people</h2>
           <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Contamos con una variedad de transpaletas que incluyen diferentes tipos de energía,  transpaletas eléctricas y transpaletas manuales. También tenemos transpaletas eléctricas en formato más pequeño y con detalles específicos,  la transpaleta eléctrica Crown. Estas transpaletas eléctricas pueden ser usadas para pesar manualmente,  la transpaleta manual de 2500 kg, y también en formato de transpaleta eléctrica elevadora que incluye la transpaleta hidráulica, conocidas  transpaletas hidráulicas.

Además, disponemos de transpaletas manuales de 1000 kg y de un nuevo <h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
 tipo de transpaleta eléctrica hombre a bordo, racks así  transpaletas Crown a un precio competitivo. Ofrecemos también transpaletas con balanza y una capacidad de 2500 kg, que pueden ser usadas  transpaletas eléctricas dobles con una carga de 1500 kg, y transpaletas de la marca Jungheinrich. Nuestra oferta incluye transpaletas manuales de 3000 kg y transpaletas eléctricas Linde.

Para una transpaleta manual de 2000 kg o transpaleta eléctrica Linde, contamos con modelos que incluyen transpaletas con pesa. La venta de nuestras transpaletas, tanto <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2> nuevas  usadas, se caracteriza por su calidad y durabilidad, ofreciendo soluciones adecuadas para diferentes necesidades de manejo de carga.</h3>
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Apilador</h2>
<h3  style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
Nuestros apiladores cuentan con una gran variedad de opciones,<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
incluyendo apiladores eléctricos, apiladores reach, y apiladores eléctricos Crown. También ofrecemos apiladores estilo Jungheinrich, conocidos  apiladores eléctricos Jungheinrich, y apiladores Linde, incluyendo el apilador manual de 1000 kg con tecnología de apilador eléctrico Linde.

Además, disponemos <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2> racks de apiladores con robótica avanzada,  el apilador BT y el apilador Crown con doble pantógrafo. Tenemos apiladores manuales  el apilador manual de 1500 kg de Jungheinrich, así  apiladores flexi y apiladores eléctricos hombre a bordo.

Nuestra oferta también incluye montacargas manuales y montacargas eléctricos, conocidos<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
montacargas elevadores. Estos se encuentran en versiones  el montacargas elevador vertical, y también se les conoce  carretillas eléctricas.
</h3>
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}> 
Apiladores eléctricos Crown,
Apiladores Jungheinrich,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for people</h2>
Apiladores eléctricos Jungheinrich,
Apiladores Linde,
Apilador manual de 1000 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>nuestro order picker stacker</h2>
Apilador eléctrico Linde,
Apilador BT,
Apilador Crown con doble pantógrafo,
Apiladores manuales,
racks
Apilador manual de 1500 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>crear order picker sud</h2>
Apiladores flexi,
racks,
Apiladores eléctricos hombre a bordo,
Montacargas manuales,
Montacargas eléctricos,
Montacargas elevadores,
Montacargas elevador vertical,
Carretillas eléctricas,
Grúa horquilla,
Grúa horquilla eléctrica,
Grúa horquilla diesel,
Montacargas manuales,
Montacargas eléctricos,
Montacargas elevador,
Montacargas elevador vertical,
Grúa horquilla Toyota,
Grúa horquilla precio,
Grúa horquilla usada,
Grúa horquilla Yale,
Grúa Linde,
Grúa horquilla 16 toneladas,
gerenracion de maquina orden picker,
Grúa horquilla 5 toneladas,
novedoso de racks,
Grúa horquilla 4x4,
Grúa horquilla 3 toneladas,
 catalogo racks,
Grúa horquilla 7 toneladas,
nuevos,
Grúa horquilla 10 toneladas,
apilador,
Grúa horquilla Heli,
Grúas Heli,
Carretilla eléctrica,
Carretillas Caterpillar,
Carretilla Cat,
Grúa horquilla Jungheinrich,
racks,
Grúa horquilla Hyster,
Grúa horquilla alto tonelaje Transpaletas,
Transpaleta eléctrica,
Transpaletas eléctricas,
racks,
Transpaleta manual,
Transpaletas manuales,
Transpaleta eléctrica Crown,
Transpaleta manual 2500 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>good order picker  for peopless</h2>
<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
    
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>creer order picker ha</h2>
Transpaleta eléctrica elevadora,
Transpaleta hidráulica,
Transpaletas hidráulicas,
Transpaleta manual 1000 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>g order picker  for perosnas</h2>
<h3 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>Alzahombres</h3>
    
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>new order picker suit</h2>
Transpaleta eléctrica hombre a bordo,
Transpaleta Crown,
Transpaleta con balanza,
Transpaletas usadas,
Transpaleta eléctrica doble,
Transpaleta eléctrica 1500 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>qqqq order picker  for grupo</h2>
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>work order picker generation</h2>
Transpaleta eléctrica Jungheinrich,
Transpaleta manual 3000 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>suity order picker developed</h2>
Transpaleta 3000 kg,
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>ioiiuiu order picker  for nues</h2>
Transpaleta hombre a bordo,
Transpaleta Linde,
Transpaleta manual 2000 kg, racks. </h2> 
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>ker order picker  for peopsle</h2>
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>reach stacker</h2>
<h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>sdsa order picker  for pseople</h2>
            </>
      )
}


export const Texto2 = () => {
      return(
            <>
            <h2 style={{fontSize:"0.1rem", color: "rgb(0 0 0 / 0%)", width: '0px', height: '0px', position: 'absolute' }}>
            Apiladores Jungheinrich,
            racks,
            Transpaleta eléctrica doble,
            racks
            Carretilla eléctrica,
            Montacargas elevadores,
            Transpaleta manual 2000 kg,
            Apiladores Linde,
            Grúa horquilla 4x4,
            racks
            Montacargas elevador vertical,
            Transpaleta hidráulica,
            Grúa horquilla diesel,
            Transpaleta usadas,
            Montacargas manuales,
            Apiladores flexi,
            Carretillas eléctricas,
            racks
             </h2>
            
            </>
            )}