import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../../style/login/administrador.css"
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import "../../style/home/home.css";
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";
import '../../style/nosotros/nosotros.css'
import '../../style/producto/producto.css';
import ReactPlayer from 'react-player';
import { TfiGallery } from "react-icons/tfi";


import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal,
    Grid,
    Select,
    MenuItem
} from "@mui/material";


export const Productosracksadmin = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [portadasData, setPortadasData] = useState([]);
    const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
    const [GaleriaData, setGaleriaData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isModalOpensgaleria, setIsModalOpensgaleria] = useState(false);
    const [isLoading, setIsLoading] = useState(true); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const fetchCategoria = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/get/racksproductos`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Enviando el token de autorización
                },
            });

            setPortadasData(response.data);  // Asignar el array `data` del backend
            if (response.status === 200 && response.data.length > 0) {
                const portadaGaleriaResponse = await axios.get(`${backendURL}/api/get/racksproductos/?id=${response.data[0].id}`);
                setPortadasGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
                setGaleriaData({ [response.data[0].id]: portadaGaleriaResponse.data });
            }
        } catch (error) {
            console.error('Error al obtener los datos de portadas:', error);
        } finally {
            setIsLoading(false); // Finaliza la carga de datos
        }
    };

    useEffect(() => {
        fetchCategoria();
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            fetchCategoria(); // Actualizar la tabla cuando el modal se cierra
        }
    }, [isModalOpen]);

  useEffect(() => {
    if (token) {
        fetchCategoria();
    }
}, [token]);

  useEffect(() => {
    setShowNavbarFooter(false);

    // Verifica si el token no existe y redirige
    if (!token) {
        logout(); 
        navigate('/stacker'); // o la ruta que desees
    }

}, [setShowNavbarFooter, token, logout, navigate]);

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenModals = (categoria) => {
        setPortadasGaleriaData(categoria); // Set the selected portada
        setIsModalOpens(true);
    };
    const handleOpenGaleria = (categoria) => {
        setGaleriaData(categoria); // Set the selected portada
        setIsModalOpensgaleria(true);
    };

    const handleClosegaleria = () => {
        setIsModalOpensgaleria(false);
    };

    const handleCloseModals = () => {
        setIsModalOpens(false);
    };
    const handleDelete = async (categoriaId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`${backendURL}/api/deleteracksAdmin/${categoriaId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                fetchCategoria();  // Refresca la lista de portadas después de la eliminación
            } else {
                console.error('Error al eliminar la Categoria:', response);
            }
        } catch (error) {
            console.error('Error al eliminar la categoria:', error);
        } finally {
            setIsLoading(false);  // Detiene el indicador de carga
        }
    };


    return(
         <>
      <Container maxWidth="lg">
        
        <Box>
            <h2 className='tituloadministrador'> Productos Racks Stacker</h2>
        </Box>
        <Box >
            <button  onClick={handleOpenModal} className='buttonaccionedit'> <MdOutlineCreateNewFolder /> <span>Crear</span></button> 
       
        </Box>
      
       
        <Box sx={{marginBottom:"100px"}}> 
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={portadasData.length} // Pasando la longitud del array
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />   
                              <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Nombre</TableCell>
                                        <TableCell>Imagen portada</TableCell>      
                                        <TableCell>Imagen web</TableCell>
                                        <TableCell>Imagen mobil</TableCell>                                 
                                        <TableCell>descripcion principal</TableCell>
                                        <TableCell>caracteristicas</TableCell>
                                        <TableCell>video</TableCell>
                                        <TableCell>SEO</TableCell>
                                        <TableCell sx={{ 
                                         position: 'sticky',
                                        right: '0',
                                        background: 'white',
                                        zIndex: '10', 
                                        textAlign: 'center'}}>
                                            Acciones
                                            </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {portadasData.length > 0 ? (
                                portadasData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((categoria) => (
                                            <TableRow key={categoria.id}>

                                                <TableCell >{categoria.nombre}</TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen_portada}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen_responsive}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell>
                                                    <img src={`${backendURL}/${categoria.imagen_sub_portada}`} alt={categoria.alt_seo} width="100" />
                                                </TableCell>
                                                <TableCell  sx={{
                                                         maxHeight: '150px',        // Altura máxima del TableCell
                                                         overflowY: 'auto',        // Habilita el scroll vertical
                                                         display: 'block',         // Permite que el contenido sea desplazable
                                                        
                                                }}>{categoria.descripcion_1}</TableCell>
                                                <TableCell  sx={{
                                                         maxHeight: '150px',        // Altura máxima del TableCell
                                                         overflowY: 'auto',        // Habilita el scroll vertical
                                                         display: 'block',         // Permite que el contenido sea desplazable
                                                        
                                                }}>{categoria.descripcion_2}</TableCell>
                                                <TableCell sx={{ width: "300px" }}> <ReactPlayer
                                                url={categoria.video}
                                                controls={true}
                                                width="300px"
                                                height="auto"
                                            /></TableCell>
                                             <TableCell>{categoria.alt_seo}</TableCell>
                                                <TableCell sx={{ 
                                         position: 'sticky',
                                        right: '0',
                                        background: 'white',
                                        zIndex: '10', 
                                        textAlign: 'center'}}>
                                                <div>
                                                 <button className='buttonaccionedit'  onClick={() => handleOpenGaleria(categoria)} >  <TfiGallery /><span>Galeria</span></button> 
                                        <button className='buttonaccionedit'  onClick={() => handleOpenModals(categoria)} > <FiEdit /> <span>Editar</span></button>
                                            
                                            
                                            <button className='buttonacciondelete'  onClick={() => handleDelete(categoria.id)}  disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                                </button>
                                        
                                        </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={18} align="center">
                                                No hay  productos racks disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                   
                </TableContainer>
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                bottom: "-1%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModal}><IoClose /></div>
                <Crearcard handleClose={handleCloseModal}  refreshPortadas={fetchCategoria} /> </Box>
                
            </Modal>

            <Modal open={isModalOpens} onClose={handleCloseModals}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "5%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleCloseModals}><IoClose /></div>
                <Editarcard
                 handleClose={handleCloseModals}
                   refreshPortadas={fetchCategoria} 
                selectedPortada={portadasGaleriaData}/>  </Box>
                
            </Modal>
            <Modal open={isModalOpensgaleria} onClose={handleClosegaleria}>
            <Box sx={{
                width: "800px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "5%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                <div className='closemodaladmin' onClick={handleClosegaleria}><IoClose /></div>
                <Galeriaproducto
                 handleClose={handleClosegaleria}
                   refreshPortadas={fetchCategoria} 
                selectedPortada={GaleriaData}/>  </Box>
                
            </Modal>
        
        
      </Container>
    
    
    </>)
}



// Componente para crear la portada
const Crearcard = ( { handleClose, refreshPortadas}) => {
    const { token} = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        nombre:'',
        imagen_portada: null,
        imagen_responsive:null,
        imagen_sub_portada:null,
        descripcion_1:'',
        video:'',     
        alt_seo: '',
        descripcion_2:'',
    });


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen_portada' || name === 'imagen_sub_portada' || name === 'imagen_responsive' ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.nombre) errors.nombre = 'El nombre es obligatorio';
        if (!formData.imagen_portada) errors.imagen = 'La imagen  portada es obligatoria';
        if (!formData.imagen_sub_portada) errors.imagen_sub_portada = 'la imagen web es obligatoria';
        if (!formData.imagen_responsive) errors.imagen_responsive = 'la imagen mobil es obligatoria';
        if (!formData.descripcion_1) errors.descripcion_1 = 'la caracteristicas es obligatoria';
        if (!formData.descripcion_2) errors.descripcion_2 = 'la caracteristicas especiales es obligatoria';
        if (!formData.video) errors.video = 'el video es obligatorio';
        if (!formData.alt_seo) errors.alt_seo = 'El texto SEO es obligatorio';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!validateForm()) {
            setErrorModalOpen(true);
            return;
        }

        setIsLoading(true);
    
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);

            
        }
        try {  const response = await axios.post(`${backendURL}/api/storePortadaracksAdmin`,formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            
            
            if (response.data.success) {
                await refreshPortadas(); 
                 setSuccessModalOpen(true);
                
            } else {
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorModalOpen(true);
            console.error('Error creating portada:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };
    
  
   
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Crear nuevo producto racks </Typography>
          
            <form onSubmit={handleSubmit}> 
            
              
                 
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Nombre
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.nombre}
                    helperText={formErrors.nombre}
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                </FormControl>   
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        video
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    error={!!formErrors.video}
                    helperText={formErrors.video}
                    name="video"
                    value={formData.video}
                    onChange={handleChange}
                />
                </FormControl>       
                
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        imagen portada  
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_portada"
                        id="imagen_portada"
                        error={!!formErrors.imagen_portada}
                    helperText={formErrors.imagen_portada}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        imagen web  
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_sub_portada"
                        id="imagen_sub_portada"
                        error={!!formErrors.imagen_sub_portada}
                    helperText={formErrors.imagen_sub_portada}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                        imagen responsive 
                    </InputLabel>
                    <TextField
                        type="file"
                        name="imagen_responsive"
                        id="imagen_responsive"
                        error={!!formErrors.imagen_responsive}
                    helperText={formErrors.imagen_responsive}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas 
                    </InputLabel>
                <TextField
                 multiline
                    fullWidth
                    margin="normal"
                    error={!!formErrors.descripcion_1}
                    helperText={formErrors.descripcion_1}
                    name="descripcion_1"
                    value={formData.descripcion_1}
                    onChange={handleChange}
                />
                </FormControl><FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                        Caracteristicas especiales 
                    </InputLabel>
                <TextField
                 multiline
                    fullWidth
                    margin="normal"
                    error={!!formErrors.descripcion_2}
                    helperText={formErrors.descripcion_2}
                    name="descripcion_2"
                    value={formData.descripcion_2}
                    onChange={handleChange}
                />
                </FormControl>
                

                
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink htmlFor="imagen" sx={{  }}>
                      SEO
                    </InputLabel>
                <TextField
                    fullWidth
                    margin="normal"
                    name="alt_seo"
                    value={formData.alt_seo}
                    onChange={handleChange}
                    error={!!formErrors.alt_seo}
                    helperText={formErrors.alt_seo}
                />
                </FormControl>
                <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
                {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
                </button>
            </form>

            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
            <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">¡Categoria creada correctamente!</Typography>
                    <button className='buttonaccionedit' onClick={() => setSuccessModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>

            {/* Error Modal */}
            <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
            <Box sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column",
                width: "400px",
                maxHeight: "90vh", // Ajusta la altura máxima según sea necesario
                overflow: "auto", // Permite el desplazamiento
                p: 4,
                position: "relative",
                top: "50%",
                left: "50%", // Ajusta la posición horizontal si es necesario
                transform: "translateX(-50%)", // Centra el modal horizontalmente
                borderRadius: "10px",
                backgroundColor: "white" // Asegúrate de que el fondo sea blanco o transparente según el diseño
            }}>
                    <Typography variant="h6" align="center">Ocurrió un error. Inténtalo de nuevo.</Typography>
                    <button className='buttonaccionedit' onClick={() => setErrorModalOpen(false)}>Cerrar</button>
                </Box>
            </Modal>
        </Box>
    );
};

const Editarcard = ({ handleClose, refreshPortadas, selectedPortada }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        nombre:'',
        imagen_portada: null,
        imagen_responsive:null,
        imagen_sub_portada:null,
        descripcion_1:'',
        video:'',     
        alt_seo: '',
        descripcion_2:'',
    });

    
 
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'imagen_portada' || name === 'imagen_sub_portada' || name === 'imagen_responsive' ) {
            setFormData({
                ...formData,
                [name]: files[0], // Store the file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    
    useEffect(() => {
        if (selectedPortada) {
            setFormData({
                
                imagen_portada: selectedPortada.imagen_portada,
                imagen_sub_portada: selectedPortada.imagen_sub_portada,
                imagen_responsive: selectedPortada.imagen_responsive,
                video: selectedPortada.video,
                nombre: selectedPortada.nombre,
                alt_seo: selectedPortada.alt_seo,
                descripcion_1: selectedPortada.descripcion_1,
                descripcion_2: selectedPortada. descripcion_2

            });
        }
    }, [selectedPortada]);
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
    try {
        const formDataToSend = new FormData();
        
        formDataToSend.append('alt_seo', formData.alt_seo);
        formDataToSend.append('nombre', formData.nombre);
        formDataToSend.append('video', formData.video);
        formDataToSend.append('descripcion_1', formData.descripcion_1);
        formDataToSend.append('descripcion_2', formData.descripcion_2);
        
        if (formData.imagen_portada) {
            formDataToSend.append('imagen_portada', formData.imagen_portada);
        }
        if (formData.imagen_sub_portada) {
            formDataToSend.append('imagen_sub_portada', formData.imagen_sub_portada);
        }
        if (formData.imagen_responsive) {
            formDataToSend.append('imagen_responsive', formData.imagen_responsive);
        }
       
        

        // Verifica los datos que se están enviando
        for (let [key, value] of formDataToSend.entries()) {
           
        }

        const response = await axios.post(`${backendURL}/api/editPortadasracksAdmin/${selectedPortada.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            refreshPortadas();
            handleClose();
        } else {
            console.error('Error al actualizar la portada:', response);
        }
    } catch (error) {
        console.error('Error al actualizar la portada:', error);
    } finally{
        setIsLoading(false);
        handleClose();
    }

    };
    return (
        <Box p={3} style={{ backgroundColor: 'white', outline: 'none' }}>
            <Typography variant="h6">Editar producto racks</Typography>

            <form onSubmit={handleSubmit}>              
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{  }}>
                    Nombre
                </InputLabel>
            <TextField
                fullWidth
                margin="normal"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
            />
            </FormControl>   
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{  }}>
                    video
                </InputLabel>
            <TextField
                fullWidth
                margin="normal"
                name="video"
                value={formData.video}
                onChange={handleChange}
            />
            <ReactPlayer
                                                url={formData.video}
                                                controls={true}
                                                width="300px"
                                                height="auto"
                                            />
            </FormControl>       
            
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                    imagen portada  
                </InputLabel>
                <TextField
                    type="file"
                    name="imagen_portada"
                    id="imagen_portada"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                {formData.imagen_portada && (
                        <img
                            src={`${backendURL}/${formData.imagen_portada}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                    imagen web  
                </InputLabel>
                <TextField
                    type="file"
                    name="imagen_sub_portada"
                    id="imagen_sub_portada"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                {formData.imagen_sub_portada && (
                        <img
                            src={`${backendURL}/${formData.imagen_sub_portada}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{ top: "-7px" }}>
                    imagen responsive 
                </InputLabel>
                <TextField
                    type="file"
                    name="imagen_responsive"
                    id="imagen_responsive"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                {formData.imagen_responsive && (
                        <img
                            src={`${backendURL}/${formData.imagen_responsive}`}
                            alt={formData.alt_seo}
                            style={{ maxWidth: '100px', marginTop: '10px' }}
                        />
                    )}
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{  }}>
                    Caracteristicas 
                </InputLabel>
            <TextField
             multiline
                fullWidth
                margin="normal"
                name="descripcion_1"
                value={formData.descripcion_1}
                onChange={handleChange}
            />
            </FormControl><FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{  }}>
                    Caracteristicas especiales 
                </InputLabel>
            <TextField
             multiline
                fullWidth
                margin="normal"
                name="descripcion_2"
                value={formData.descripcion_2}
                onChange={handleChange}
            />
            </FormControl>
            

            <FormControl fullWidth margin="normal">
                <InputLabel shrink htmlFor="imagen" sx={{  }}>
                  SEO
                </InputLabel>
            <TextField
                fullWidth
                margin="normal"
                name="alt_seo"
                value={formData.alt_seo}
                onChange={handleChange}
            />
            </FormControl>
            <button  className='buttonaccionedit'  type="submit"  disabled={isLoading}>
            {isLoading ? (<CircularProgress/>):(<> Guardar </>)}
            </button>
        </form>
        </Box>
    );
};
const Galeriaproducto = ({ selectedPortada }) => {
    const { token } = useAuth();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [galeriaData, setGaleriaData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const fetchGaleriaData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/get/all/galeriaracks`);
            setGaleriaData(response.data);
        } catch (error) {
            console.error('Error fetching galeria data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedPortada) {
            fetchGaleriaData();
        }
    }, [selectedPortada]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleShowForm = () => {
        setShowForm(!showForm);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setNewImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (newImage && selectedPortada) {
            const formData = new FormData();
            formData.append('img', newImage);
            formData.append('id_racks', selectedPortada?.id);

            try {
                await axios.post(`${backendURL}/api/storeGaleriaRacksAdmin`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                fetchGaleriaData();
                setShowForm(false);
                setNewImage(null);
                setImagePreview('');
            } catch (error) {
                console.error('Error adding new image:', error);
            }
        }
    };

    const handleDelete = async (id) => {
       
        try {
            await axios.delete(`${backendURL}/api/deleteApigaleriaracks/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            fetchGaleriaData();
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    return (
        <div style={{ marginTop: "100px" }}>
            <Typography variant="h6">Galeria Racks {selectedPortada?.nombre}</Typography>

            <Box>
                <button className='buttonaccionedit' onClick={handleShowForm}>
                    <MdOutlineCreateNewFolder /> <span>Crear imagen galería</span>
                </button>
            </Box>
            {showForm && (
                <Box marginTop={2}>
                    <Typography variant="h6">Agregar Imagen</Typography>
                    <input type="file" onChange={handleImageChange} />
                    {imagePreview && (
                        <Box marginTop={1}>
                            <img src={imagePreview} alt="Vista previa" width="100" />
                        </Box>
                    )}
                    <button className='buttonaccionedit' onClick={handleSubmit} style={{ marginTop: '10px' }}>
                        Subir Imagen
                    </button>
                </Box>
            )}

            <TableContainer component={Paper}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={galeriaData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Racks</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">Cargando...</TableCell>
                            </TableRow>
                        ) : galeriaData.length > 0 ? (
                            galeriaData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{selectedPortada?.nombre}</TableCell>
                                    <TableCell>
                                        <img src={`${backendURL}/${item.img}`} alt="stacker" width="100" />
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            
                                            <button 
                                                className='buttonacciondelete'
                                                onClick={() => handleDelete(item.id)}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <CircularProgress size={24} /> : (<><BsTrash /> <span>Eliminar</span></>)}
                                            </button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No hay datos disponibles</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};