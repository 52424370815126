import React from "react";
import Portadaracks from "../components/racks/portada";
import Racks from "../components/racks/racks-prodcuto";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Rack() {
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "name": "STACKER",
        "url": "https://www.stacker.cl",
        "logo": "https://stacker.cl/static/media/logofooter.9e9c2c7329a96366b64b.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+56990792892",
          "contactType": "Customer Service"
        },
        "sameAs": [
          "https://www.facebook.com/StackerChile",
          "https://www.instagram.com/stacker_chile/",
          "https://www.youtube.com/@StackerChile",
          "https://www.linkedin.com/company/stacker-itcl/",
          "https://x.com"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "STACKER Chile",
        "image": "https://www.qadminstacker.alerama.cl/imagenes/portadas/galeria/1705031478_grua%20.png",
        "description": "STACKER | Grúas Horquillas, transpaletas, Apiladores y Racks",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Dirección de su negocio",
          "addressLocality": "Ciudad",
          "addressRegion": "Región",
          "postalCode": "Código Postal",
          "addressCountry": "CL"
        },
        "telephone": "+56990792892",
        "priceRange": "$$",
        "openingHours": "Mo-Fr 08:00-17:00",
        "url": "https://www.stacker.cl"
      },
      {
        "@type": "WebPage",
        "name": "Productos",
        "url": "https://stacker.cl/productos",
        "datePublished": "2024-07-01",
        "image": "https://www.qadminstacker.alerama.cl/imagenes/portadas/galeria/1705031478_grua%20.png",
        "description": "Conoce la variedad de máquinas que contamos para equipar tus necesidades.",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://stacker.cl/productos"
        }
      }
    ]
  };
    
    return(
        <>
         <HelmetProvider>
          <Helmet>
  <title>STACKER | Nuestros racks todo tipo de almacenado.</title>
  <meta
    name="description"
    property="og:description"
    content="Nuestros racks están diseñados para resistir altas cargas y ofrecen una solución robusta y eficiente para el almacenamiento de una amplia variedad de productos."
  />
  <meta
    name="keywords"
    property="og:description"
     content="grúas horquillas, transpaletas, apiladores, pasillo angosto, reach stacker, alzahombres, order picker, racks."
  />
  <meta property="og:title" content="STACKER | Nuestros racks todo tipo de almacenado." />
  <meta property="og:type" content="website" />
          <meta property="og:url" content="https://stacker.cl" />
          <meta property="og:title" content="STACKER | Grúas Horquilla, transpaletas, Apiladores y Racks" />
          <meta property="og:image" content="https://stacker.cl/static/media/logofooter.9e9c2c7329a96366b64b.png" />
          <meta name="author" content="STACKER Chile" />
          <meta name="robots" content="index,follow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="publisher" content="STACKER Chile" />
          <link rel="canonical" href="https://www.stacker.cl" />
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
</Helmet>
</HelmetProvider>
        <Portadaracks/>
        <Racks/>
        </>
    )
}