
import React, {useEffect} from "react";
import { Grid } from "@mui/material";
import Formularioservicio from "./formulario-servicio";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
/* imagenes */
import fondo from "../../img/servicio/servicio.png"

export default function ContactoServicio () {
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 2000,
    });
  }, []);
    const sectionStyle = {
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        margin: '0px 0px -30px',
        
        // Otros estilos que desees aplicar
      };
    return(
        <section style={sectionStyle}>
<Grid container sx={{display:"flex", alignItems:"center", justifyContent:"center", '@media (max-width: 898px)': {
     paddingTop:"100px"
    },}}>

<Grid item sm={12} md={6}> <div className="contiene-servicio-formulario" data-aos="fade-down"> <Formularioservicio/></div></Grid>
<Grid item sm={12} md={6} className="serviciotecnico">  <div className="contiene-contacto-serviciotecnico">

<div className="info-serviciotecnico">
                            <h2 className="titulo-serviciotecnico">Contáctanos para obtener asesoramiento personalizado 
</h2>
                       <h3 className="subtitulo-serviciotecnico">¡Comunicarte con nosotros es muy fácil! Puedes hacerlo a través de nuestras diversas redes sociales, por correo electrónico o teléfono.</h3>
                        </div>

<div className="conteneredor-redes-sociales-contacto">
                            <button className="contacto-icono-redes">
                            <a
                            title="whatssapp"
                  href="https://wa.me/+56990792892?text=Quiero información o cotizar por sus servicios y productos"
                  target="_blank"
                >
                                <FaWhatsapp className="iconos-contacto" />
                                </a>
                            </button>
                            <button className="contacto-icono-redes">
                            <a
                            title="linkeding"
                  href="https://www.linkedin.com/company/stacker-itcl/"
                  target="_blank"
                >
                            
                                <FaLinkedinIn className="iconos-contacto" />
                                </a>
                            </button>
                            <button className="contacto-icono-redes">
                            <a
                            title="facebook"
                  href="https://www.facebook.com/StackerChile"
                  target="_blank"
                >  <FaFacebookF className="iconos-contacto" />
                </a>
                            </button>

                           
                            <button className="contacto-icono-redes-1">
                            <a href="mailto:contacto@stacker.cl" target="_blank" title="email">
                                <MdOutlineEmail  className="iconos-contacto-1" />
                            </a>
                            </button>
                            <button className="contacto-icono-redes-1">
                            <a href="tel:+56990792892" target="_blank" title="telefono">
                                <AiOutlinePhone className="iconos-contacto-1" />
                          </a>
                            </button>
                        </div>
                       
    
</div></Grid>

</Grid>


        </section>
    )
}