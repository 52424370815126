
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import {  Container } from "@mui/material"
import"../../style/estrcutura producto/productestruc.css"
import "../../style/estructura racks/estrucrack.css"

import AOS from 'aos';
import 'aos/dist/aos.css'; 

/* imagenes */

import check from "../../img/racks/estructura/check.png"

export default function Descripcionproducto({ producto }) {

    useEffect(() => {
        AOS.init({
          // Configura las opciones aquí
          duration: 1000,
        });
      }, []);
 
    return (
        <>
       
       <Container  maxWidth="lg" >
        <section className="section-descripcion-general" >

            <div className="titulo-descripcion-general-prducto" data-aos="fade-down">
                <img className="imagen-titulo-generalprducto" src={check} alt="stacker" title="stacker" />
                <h3 className="titulo-descripcion-general">CARACTERISTICAS ESPECIALES</h3>
                </div>
        </section>
        <section className='section-caracterizacion-prducto' data-aos="fade-down">
            <div className='texto-caracterizacion'>
         <h2>{producto.caracteristicas_especiales}</h2> 
            </div>
            <div className='video-caracterizacion-producto' data-aos="fade-down">
       
            <ReactPlayer url={producto.video} className="videoproducto" width="90%" height="100%" style={{ maxHeight: '70vh' }}   loop controls/>
            </div>
        </section>

        
        </Container>
        
        </>
    )
}