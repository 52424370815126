
import React, { useState, useEffect } from "react";
import "../../style/home/home.css";
import axios from "axios";
import { Grid, Container } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Texto2 } from "./maquinarias";
import ReactPlayer from "react-player";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

/* imagenes */

import Lineas from "../../img/home/contactohome/lineas.png";


export default function ClientesHome() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [cliente, setCliente] = useState([]);
 const backendURL = process.env.REACT_APP_BACKEND_URL;  

 const handleOpenModal = (url) => {
  setModalOpen(true);
  setSelectedVideoUrl(url);
};

const handleCloseModal = () => {
  setModalOpen(false);
  setSelectedVideoUrl('');
};

  useEffect(() => {
    // Realiza la solicitud a la API al cargar el componente
    axios.get(`${backendURL}/api/get/all/videos`)
      .then(response => {
        // Filtra los servicios que tienen tipo 'cliente' en su array
        const clientes = response.data.filter(video => video.tipo === 'cliente');
        setCliente(clientes);
      })
      .catch(error => {
        console.error('Error fetching servicios:', error);
      });
  }, []);

 

  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);
  return (
    <>
    <section className="clientes-home-principal" data-aos="fade-down"
     >
      <Texto2/>
      <Container maxWidth="xl" >
       <section className="titulo-nosotros-home" >
    <img src={Lineas}  className="imagenlineastitulos"  alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"  title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"  height="100%"
          width="100%" />
    <p className="titulo-home-nosotros" aria-hidden="true" tabIndex="-1"> NUESTROS PRINCIPALES CLIENTES</p>
   
      </section>
      <section className="titulosubtituloclientehome" >
       <span className="subtitulocliente">Conoce los testimonios de algunos de nuestros clientes para escuchar sus experiencias con nuestros servicios</span>
     </section>
      <div className="clientes-home-testimonio">
        <Grid container className="slider-clientes-home">
          <Swiper
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              240: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              900: { slidesPerView: 3, spaceBetween: 1 },
              1565: {
                slidesPerView: 4,
                spaceBetween: 1,
              },
              1566: {
                slidesPerView: 5,
                spaceBetween: 1,
              },
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            
            className="mySwiper"
          >
            {cliente.map(clienteItem => (
  <SwiperSlide key={clienteItem.id} className="contenedor-cliente">
    

    <div className="card-container">
           
    {clienteItem.url && clienteItem.url.trim() !== "" && (
  <div className="fondocliente">
    <button
      className="buttonvideoclientehome"
      onClick={() => handleOpenModal(clienteItem.url)}
      aria-hidden="true"
      tabIndex="-1"
    >
      <svg
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width="26px"
      >
        <path
          d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
          fill="currentColor"
        ></path>
      </svg>
    </button>
  </div>
)}
      {clienteItem.imagen && (
         <picture>
       <source srcSet={`${backendURL}/${clienteItem.imagen}`} type="image/webp" />
        <img 
          className="video-logo-imagen"
          src={`${backendURL}/${clienteItem.imagen}`}
          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          height="100%"
          width="100%"
         
        />
        </picture>
       )}
      {clienteItem.logo && (
        <div>
        <div className="iluminado-overlay"></div>
      
       <div className="contieneimagenlogocliente"> 
        
       <picture>
        
       <source srcSet={`${backendURL}/${clienteItem.logo}`} type="image/webp" />
       <img
          className="img-logo-cliente-home"
          src={`${backendURL}/${clienteItem.logo}`}
          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          height="100%"
          width="100%"
        />
        </picture>
       
        
         </div>
         
      

              </div>)}
              </div>
            </SwiperSlide>
             ))}
          </Swiper>
        </Grid>
      </div>
   </Container>
   
    </section>
    {modalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
            <ReactPlayer
             url={selectedVideoUrl}
             controls={true} 
              className="video-cliente"         
               title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                />
  
           
          </div>
        </div>
      )}
    </>
  );
}
