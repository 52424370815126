import React from "react";
import ContactoServicio from "../components/serviciotecnico/contacto-servicio";
import { Helmet } from "react-helmet";

export default function Servicios (){
    return(
        <>
        <Helmet>
  <title>STACKER | Contáctanos para obtener asesoramiento personalizado.</title>
  <meta
    name="description"
    property="og:description"
    content=" Realiza tus consultas para tener asesoramiento personalizado por nuestros servicios de arriendo y venta  de las máquinas a la modalidad que mas te acomode."
  />
  <meta
    name="keywords"
    property="og:description"
     content="grúas horquillas, transpaletas, apiladores, pasillo angosto, reach stacker, alzahombres, order picker, racks."
  />
  <meta property="og:title" content="STACKER | Contáctanos para obtener asesoramiento personalizado." />
  <meta property="og:type" content="website" />
  <meta name="author" content="STACKER" />
        <meta name="robots" content="index,follow" />
        <meta property="og:url" content="https://stacker.cl" />
        <meta property="og:url" content="https://stacker.alerama.cl/Productos" />
        <meta property="og:url" content="https://stacker.alerama.cl/Catalogo" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="publisher" content="stacker" />
        <link rel="canonical" href="http://www.stacker.cl/servicios" />
</Helmet>
        
        
        <ContactoServicio/></>
    )
}