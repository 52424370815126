import React from "react";
import "../../style/footer/footer.css";
/* import React Icons */
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";
import { BsTelephoneFill, BsEnvelope } from "react-icons/bs";
import { Grid, Container } from "@mui/material";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
/* imagenes */
import Logo from "../../img/footer/logofooter.png";

export const  Footer = () => {
  return (

    <section className="footer">
      
        <div className="contenedor-footer">
           <Container maxWidth="xl" >
      <Grid container>
        <Grid item xs={12}  md={8} sx={{ display:'flex', alignItems:'center', justifyContent:'flex-start',  '@media (max-width: 899px)': {display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'},}}>
          <div className="contenedor-img-footer">
            <div className="logo-footer">
            
              <img src={Logo} alt='stacker' title='stacker' className="logofooter"   height="100%"
          width="100%" />
            </div>
            
            <div className="contiene">
              
              <div className="linea"></div>
            <ul>
               <li className="contacto-footer-dos">
              <a href="/productos" title="productos">
                <p className="info-lista-contacto-footer">Productos</p>{" "}
                </a>
              </li>
              <li className="contacto-footer-dos">
              <a href="/catalogo" title="catalogo">
                <p className="info-lista-contacto-footer">Racks</p>{" "}
                </a>
              </li>
              <li className="contacto-footer-dos">
              <a href="/servicios" title="servicios">
                <p className="info-lista-contacto-footer">
                  Servicios 
                </p>{" "}
                </a>
              </li>
             
            </ul></div>
          
           
           
      
          </div>
        </Grid>
        <Grid item xs={12}  md={4}>
          <div className="info-footer">
          
               <div className="redes-footer">
              <button className="icono-redes-footer">
                <a
                  href="https://wa.me/+56990792892?text=Quiero informacion o cotizar por sus servicios y productos"
                  target="_blank"
                  title="whatssap"
                >
                  <FaWhatsapp className="iconos-footer" />
                </a>
              </button>
              <a
                  href="https://www.instagram.com/stacker_chile/"
                  target="_blank"
                  title="instagram"
                >
              <button className="icono-redes-footer">
              <FaInstagram  className="iconos-footer" />
              </button></a>
              <a
                  href="https://www.linkedin.com/company/stacker-itcl/"
                  target="_blank"
                  title="linkedin"
                >
              <button className="icono-redes-footer">
                <FaLinkedinIn className="iconos-footer" />
              </button></a>
              <a
                  href="https://www.facebook.com/StackerChile"
                  target="_blank"
                  title="facebook"
                >
              <button className="icono-redes-footer">
                <FaFacebookF className="iconos-footer" />
              </button>
              </a>
              <a
                  href="https://www.youtube.com/@StackerChile/featured"
                  target="_blank"
                  title="youtube"
                >
                            <button className="icono-redes-footer">
                                <FaYoutube className="iconos-footer" />
                            </button>
                            </a>
            
            </div>
            <div className="finalfooter">
            <ul>
              <li className="contacto-footer">
                {" "}
                <a href="mailto:contacto@stacker.cl" target="_blank" title="email">
                  <BsEnvelope className="redes-sociales-footer-svg" />
                  <p className="info-lista-contacto-footer">
                    contacto@stacker.cl
                  </p>{" "}
                </a>
              </li>
              <li className="contacto-footer">
                {" "}
                <a href="tel:+56990792892" title="telefono">
                  <BsTelephoneFill className="redes-sociales-footer-svg" />
                  <p className="info-lista-contacto-footer">
                    +569 9079 2892
                  </p>{" "}
                </a>
              </li>
            </ul></div>
          </div>
        </Grid>
      
      </Grid></Container>
      <Grid container className="footer-final">
        <hr className="separador-footer"/>
        <span className="info-final-footer"> STACKER 2024.</span>
      </Grid>
      </div>
      
    </section>
  );
}
