
import "../../style/carrito/carro.css";
import { Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import { useEffect,  useState } from "react";
import AddIcon from '@mui/icons-material/Add'; 
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import axios from "axios";
 import { useCarritoContexto } from "../context/cotizar-final"; 
import { useCarrito } from "../context/carrito-cotizar";



export default function Carro() {
      const { agregarcotizar } = useCarritoContexto();
    const { cantidadProductos, productosEnCarrito, eliminarProductoDelCarrito } = useCarrito();
    const [cantidades, setCantidades] = useState({});
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    

  useEffect(() => {
  

    // Filtra los productos que son de tipo "Maquina"
    const maquinasEnCarrito = productosEnCarrito.filter((producto) => producto.tipo === "maquina");
    
    const racksEnCarrito = productosEnCarrito.filter((producto) => producto.tipo === "rack");

    // Realizar solicitudes GET solo para productos de tipo "Maquina"
    maquinasEnCarrito.forEach(async (maquina) => {
        try {
          const response = await axios.get(`${backendURL}/api/get/productos/${maquina.id}`);
          const detallesMaquina = response.data;
        
          // Puedes hacer lo que necesites con la información detallada de la máquina
        } catch (error) {
          console.error('Error al obtener detalles de la máquina', error);
        }
      });
  
      // Realizar solicitudes GET solo para productos de tipo "Rack"
      racksEnCarrito.forEach(async (rack) => {
        try {
          const response = await axios.get(`${backendURL}/api/get/racksproductos/${rack.id}`);
          const detallesRack = response.data;
       
          // Puedes hacer lo que necesites con la información detallada del rack
        } catch (error) {
          console.error('Error al obtener detalles del rack', error);
        }
      });

      const nuevasCantidades = {};
      maquinasEnCarrito.forEach((maquina) => {
        nuevasCantidades[maquina.id] = nuevasCantidades[maquina.id] || 1;
      });
      racksEnCarrito.forEach((rack) => {
        nuevasCantidades[rack.id] = nuevasCantidades[rack.id] || 1;
      });
      setCantidades(nuevasCantidades);
    }, [cantidadProductos, productosEnCarrito, eliminarProductoDelCarrito]);
  
   // Función para incrementar la cantidad de un producto
const incrementarCantidad = (id) => {
  setCantidades((prevCantidades) => ({
    ...prevCantidades,
    [id]: (prevCantidades[id] || 0) + 1,
  }));
};

// Función para decrementar la cantidad de un producto
const decrementarCantidad = (id) => {
  if (cantidades[id] > 0) {
    setCantidades((prevCantidades) => ({
      ...prevCantidades,
      [id]: prevCantidades[id] - 1,
    }));
  }
};

const cotizarProductos = () => {


  // Itera sobre los productos en el carrito y envía la información al contexto de cotización
  productosEnCarrito.forEach((producto) => {
    const { id, tipo, imagen, nombre } = producto;
    const cantidad = cantidades[producto.id] || 1;
    agregarcotizar({ id, tipo, imagen, nombre, cantidad });

    // Agrega un console.log para imprimir los datos
   
  });

  // Resto del código...
};
    
   
  

   if ( cantidadProductos  === 0) { 
    return (
      <section className="vaciocarrito">
        <div>
          <div className="titulo-carro">
            <h2>Maquina y Rack Selección</h2>
          </div>
          <div className="productoscotizarvacio">
            <div className="inconovacio">
              <ShoppingCartCheckoutIcon/>
              </div>
              <div className="infovacio">
                <h3>Tu carrito está vacío</h3>
                
                  <a href="/productos">
          <Button variant="outlined" className="boton-navegar">
            Regresar
          </Button></a>
     

              </div>
          </div>
        </div>
      </section>
    );
  }
   return (
    <Container  maxWidth="xl" >
    <section className="carrito"> 
        <div>
       <div className="titulo-carro">
          <h2>Maquina y Rack Selección</h2>
        </div>
         <Grid container>
          <Grid item xs={12} sm={6} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
           <Grid container sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
              {" "}
              {productosEnCarrito && productosEnCarrito.map((producto) => (
              <Grid item xs={12} key={producto.id}>
                <div className="productos-cotizar">
                  <div className="imagen">
                  {producto.imagen &&  (
           
         <img
            src={
                 `${backendURL}/${producto.imagen}`   
            }
            alt={producto.seo}
            className="iamgencarrito"
          />
        ) }
                  </div>
                  <div className="info-shop">
                    <span>{producto.nombre}</span>
                    <p>{producto.precio}</p>
                  </div>
                  <div className="cantidad-producto">
                      
                      <button  className="button-cantidad" onClick={() => decrementarCantidad(producto.id)}><HorizontalRuleIcon/></button>
                      <span>{cantidades[producto.id] || 1}</span>
                      <button className="button-cantidad" onClick={() => incrementarCantidad(producto.id)}><AddIcon/></button>
                    </div>
                  <div className="buton-eliminar">
                    <Tooltip title="Delete" sx={{padding:'0px'}}>
                      <IconButton onClick={() => eliminarProductoDelCarrito(producto.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>

                  
                </div>
              </Grid>
            ))}
            </Grid>
          </Grid>
          <Grid  item xs={12} sm={6} sx={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end'}}>
            <Card className="contiene-cotizar-final">
              <CardContent className="informacioncot">
                <h2>Finalizar Cotización </h2>
                <div className="subindo">
                  <span>Número Selecionados</span>
                  <span>{cantidadProductos}</span> 
                </div>
              </CardContent>
              <CardActions>
                <a href="/Contacto">
                <button className="animated-button" onClick={cotizarProductos}>
                  <span>Cotizar</span>
                  <span></span>
                </button></a>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="navegar">
        <Link href="/productos">
          <Button variant="outlined" className="boton-navegar" sx={{ color:"#115c67",borderColor:'#115c67'}}>
            Continuar Navegando
          </Button>
        </Link>
      </div>
    </section>
    </Container>
  ); 
}
