import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/context/tokenadmin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import "../style/login/administrador.css"
import "../style/racks/racks.css";
import imagenvideo from "../img/home/contactohome/2.png";
import imagen from "../img/home/portada/adportada.png";
import imagenss from "../img/admin/stacker.gif";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
    CircularProgress,
    InputLabel,
    FormControl,
    TextField,
    Grid,
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const Administrador = ({ setShowNavbarFooter }) => {
    const { token, logout } = useAuth();
    const navigate = useNavigate();
    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [data, setData] = useState([]);
    const [racksData, setRacksData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [cliente, setCliente] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [videos, setVideos] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

    useEffect(() => {
        setShowNavbarFooter(false);

        // Verifica si el token no existe y redirige
        if (!token) {
            logout(); 
            navigate('/stacker'); // o la ruta que desees
        }
    }, [setShowNavbarFooter, token, logout, navigate]);

    const handleOpenModal = (url) => {
        setModalOpen(true);
        setSelectedVideoUrl(url);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedVideoUrl('');
      };

      
  useEffect(() => {
    axios
      .get(`${backendURL}/api/get/racksproductos`)
      .then((response) => {
        setRacksData(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

    useEffect(() => {
        const fetchDatas = async () => {
            try {
                // Obtener datos de videos
                const response = await axios.get(`${backendURL}/api/get/all/videos`);
                const videos = response.data;

                // Contar videos por tipo
                const typeCounts = videos.reduce((acc, video) => {
                    const { tipo } = video;
                    if (acc[tipo]) {
                        acc[tipo]++;
                    } else {
                        acc[tipo] = 1;
                    }
                    return acc;
                }, {});
               
                const totalVideos = videos.length;
                // Transformar el objeto en un array de objetos para la gráfica
              // Transformar el objeto en un array de objetos para la gráfica y la tabla
              const chartData = Object.keys(typeCounts).map(tipo => ({
                name: tipo,
                count: typeCounts[tipo],
                percentage: (typeCounts[tipo] / totalVideos) * 100 // Calcular porcentaje
            }));
                setDatas(chartData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchDatas();
    }, [backendURL]);

    


     useEffect(() => {
       // Realiza la solicitud a la API al cargar el componente
       axios.get(`${backendURL}/api/get/all/videos`)
         .then(response => {
           // Filtra los servicios que tienen tipo 'cliente' en su array
           const clientes = response.data.filter(video => video.tipo === 'cliente');
           setCliente(clientes);
           const portadaServicios = response.data.filter(videos => videos.tipo === 'servicios');
           setServicios(portadaServicios);
           const portadaVideos = response.data.filter(video => video.tipo === 'portada');
        setVideos(portadaVideos);
         })
         .catch(error => {
           console.error('Error fetching servicios:', error);
         });
     }, []);


     useEffect(() => {
      const fetchData = async () => {
          try {
              const categoriasResponse = await axios.get(`${backendURL}/api/get/all/categorias`);
              const productosResponse = await axios.get(`${backendURL}/api/get/all/productos`);
  
              const categoriasData = categoriasResponse.data;
              const productosData = productosResponse.data;
  
              
  
              const totalProductos = productosData.length;
              
              // Convertir todos los id_categoria de productos a números
              const productosDataNumeros = productosData.map(producto => ({
                  ...producto,
                  id_categoria: Number(producto.id_categoria)
              }));
  
              // Construir un mapa para contar los productos por categoría
              const conteoProductosPorCategoria = productosDataNumeros.reduce((acc, producto) => {
                  if (!acc[producto.id_categoria]) {
                      acc[producto.id_categoria] = 0;
                  }
                  acc[producto.id_categoria]++;
                  return acc;
              }, {});
  
              // Calcular los datos finales para cada categoría
              const productosPorCategoria = categoriasData.map(categoria => {
                  const cantidad = conteoProductosPorCategoria[categoria.id] || 0;
                  const porcentaje = (cantidad / totalProductos) * 100;
  
  
                  return {
                      id: categoria.id,
                      nombre: categoria.descripcion,
                      cantidad,
                      porcentaje: porcentaje.toFixed(2)
                  };
              });
  
            
  
              setData(productosPorCategoria);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };
  
      fetchData();
  }, []);
    const COLORS = ['#169EA9', '#0D9FC0 ', '#28B295', '#3CB091', '#82F0D2', '#9C7FCE','#7FB0CE', '#0E9AF0', '#7ACEA6', '#25CB7B', '#58E3D2', '#8E8EF0' ];

        return (
            <> 

                  
            <Container maxWidth="md">
              
                <Box sx={{
                  width:'100%',
                  height:'100%',
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                position:"relative",
                marginTop:'50px'
            }}>
                    <img src={imagenss} alt='stacker' style={{width:"100%", height:"100%"}} />
                   
                </Box>
{/* <Box sx={{ margin: '20px' }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Pagina de inicio</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box textAlign="center" mt={5}>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Card de la vista de inicio </h2>
                <hr className='barraseparador'/></div>
           <Grid container sx={{display:"flex", alignItems:"center", justifyContent:"center"}}> 
            <Grid item xs={6}> {loading ? (
                    <CircularProgress />
                ) : (
                   
                )}</Grid>
            <Grid item xs={6} >  
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoría</TableCell>
                                                        <TableCell align="right">Cantidad de card</TableCell>
                                                        <TableCell align="right">Porcentaje</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {datas.map((row) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="right">{row.count}</TableCell>
                                                            <TableCell align="right">{row.percentage.toFixed(2)}%</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer></div>
                                        </Grid>
           </Grid>
               
            </Box>
            <Box>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Card Cliente </h2>
                <hr className='barraseparador'/></div>
                <div>
       
          <Swiper
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              
              1566: {
                slidesPerView: 5,
                spaceBetween: 1,
              },
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            
            className="mySwiper"
          >
            {cliente.map(clienteItem => (
  <SwiperSlide key={clienteItem.id} >
   

    <div className="card-container">
           
      {clienteItem.imagen && (
        
        <img 
          className="video-logo-imagen"
          src={`${backendURL}/${clienteItem.imagen}`}
          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          height="100%"
          width="100%"
         
        />
       
      )}
      <div className="iluminado-overlay"></div>
      {clienteItem.logo && (
       <div className="contieneimagenlogocliente"> 
       <picture>
       <source srcSet={`${backendURL}/${clienteItem.logo}`} type="image/webp" />
       <img
          className="img-logo-cliente-home"
          src={`${backendURL}/${clienteItem.logo}`}
          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker alzahombres, transpaleta hombre a bordo, montacargas"
          height="100%"
          width="100%"
        />
        </picture>
        
         </div>
      )}

              </div>
            </SwiperSlide>
             ))}
          </Swiper>
        
      </div>
      </Box>
      <Box>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Card portada</h2>
                <hr className='barraseparador'/></div>
                <Grid container className="contenedor-home" sx={{padding:"0px"}} >
        <Grid item xs={12} sm={6} className="subcontenedor-home-info-primero">
          {videos.map(video => (
            <div key={video.id} className="video-principal-home">
              {video.imagen && (
               <div  className="contieneimagenvideohome" > 
                <picture>
                <source srcSet={imagenvideo} type="image/webp" />
               <img
                  src={imagenvideo}
                  alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                  className="imagen-video-segunda-info-home"
                    height="100%"
                    width="100%"
                 
                />
                </picture>
                </div>
              )}
              <button className="button-video-home" onClick={() => handleOpenModal(video.url)} aria-hidden="true" tabIndex="-1">
                <svg
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="26px"
                >
                  <path
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
          ))}
        
      </Grid>
      </Grid>
                </Box>
                <Box>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Card Servicios</h2>
                <hr className='barraseparador'/></div>
                <Grid container sx={{marginTop:'70px'}} >
        {servicios.map(servicio => (
          <Grid key={servicio.id} item xs={6} sm={3} md={3} lg={3} sx={{padding:'10px', '@media (max-width: 400px)': {padding:'1px'}}} >
            <a href={servicio.url} title="servicios">
              <div className="card-servicio-home">
                <div className="img-contenedor-servicio-home">
                  {servicio.imagen && (
                    <picture>
                    <source srcSet={`${backendURL}/${servicio.imagen}`} type="image/webp" />
                    <img
                        src={`${backendURL}/${servicio.imagen}`}
                        alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                        title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                        className="contenedorservicioshome"
                          height="100%"
                          width="100%"
                    />
                </picture>
                  )}</div>
                  <div className="info-servicio">
                    <h3 className="titulo-servicios-home" aria-hidden="true" tabIndex="-1">{servicio.titulo}</h3>
                   
                    
                    <div className="contieneinfodescripcionservicio">
                    <p className='subtitulodos-servicio-home' aria-hidden="true" tabIndex="-1">{servicio.descripcion}</p>
                    </div>
                  </div>
                
                <button className="button-servicios-home" aria-hidden="true" tabIndex="-1">{servicio.titulo}</button>
              </div> 
             
            </a>
          </Grid>
        ))}
        </Grid>
        {modalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div>
              <iframe
                className="video-cliente"
                src={selectedVideoUrl}
                title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
       <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
              <iframe
                className="video-cliente"
                src="https://stacker.cl"
                title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
                </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Pagina de productos maquinaria</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Categoría</TableCell>
                                        <TableCell align="right">Cantidad productos</TableCell>
                                        <TableCell align="right">Porcentaje</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.map((row) => (
                                      <TableRow key={row.id}> {/* Usar id de categoría como key */}
                                          {/* <TableCell component="th" scope="row">
                                              {row.nombre}
                                          </TableCell>
                                          <TableCell align="right">{row.cantidad}</TableCell>
                                          <TableCell align="right">
                                              {row.porcentaje } %
                                          </TableCell>
                                      </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{marginTop:"100px", marginBottom:"100px"}}>
            <h2 className='tituloadmincate' style={{marginBottom:"50px"}}>Categorías</h2>
                        {data.length > 0 ? (
                            <PieChart width={1000} height={400}>
                                <Pie
                                    data={data}
                                    dataKey="cantidad"
                                    nameKey="nombre"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={150}
                                    fill="#444444"
                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        ) : (
                            <p style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <CircularProgress/> Cargando datos...
                            </p>
                        )}
                    </Box>
               
            <Box>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Productos por Categoría</h2>
                <hr className='barraseparador'/></div>
                <Box display="flex"  alignItems="center" justifyContent="center" sx={{marginTop:"40px", marginBottom:"100px"}}>
                    
                            <Grid Container>
                            <Grid container spacing={2}>
            {data.length > 0 ? (
                data.map((categoria, index) => (
                    <Grid item key={index} sx={12} md={3}>
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <h3 className='titulo-pie'>{categoria.nombre}</h3>

                            <PieChart width={250} height={250}>
                                <Pie
                                    data={[{ ...categoria }]}
                                    dataKey="cantidad"
                                    nameKey="nombre"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={90}
                                    fill="#444444"
                                    label={categoria.porcentaje}
                                >
                                    <Cell fill={COLORS[index % COLORS.length]} />
                                </Pie>
                                <Tooltip />
                            </PieChart>

                            <span className='subtitulopie'>{categoria.cantidad} Total de productos</span>
                            <span className='subtitulopie'>{categoria.porcentaje} % del total de productos</span>
                        </Box>
                    </Grid>
                ))
            ) : (
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: 4 }}>
                    <CircularProgress /> Cargando datos...
                </Box>
            )}
        </Grid>
                        </Grid>
                    

                </Box>
            </Box>
            <Box> {categorias.length > 0 ? (
        <Swiper
          slidesPerView={4}
          spaceBetween={0}
          breakpoints={{
          
            1565: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="sliderhomeproducto"
        >
          {categorias.map((categoria) => (
            <SwiperSlide key={categoria.id} className="categoria-slider-home" >
              <div className="categoria-home"  >
                <div className="cardcontenidocategoria">
                  <div className="imagen-categoria-producto-home">
                    {categoria.imagen && (
                      <picture>
                        <source srcSet={`${backendURL}/${categoria.imagen}`} type="image/webp" />
                        <img
                          src={`${backendURL}/${categoria.imagen}`}
                          alt="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                          title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                            height="100%"
                            width="100%"
                        />
                      </picture>
                    )}
                  </div>
                  <div className="info-categoria-home">
                    <button aria-hidden="true" tabindex="-1" className="seobuttonhidden ">
                  <p className="titulo-contenido-categoria" aria-hidden="true" tabindex="-1">{categoria.descripcion} </p>
                  </button>
                 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Cargando categorías...</p>
      )}</Box>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:'100%'}}>
              <iframe
                
                src="https://stacker.cl/productos"
                title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                style={{width:'1200px', height:'500px'}}
               
              ></iframe>
            </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Pagina de productos Racks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                        
                    <Box>  
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <h2 className='tituloadmincate'>Card rancks</h2>
                <hr className='barraseparador'/></div>
        
                         <Grid container>
            {racksData.map((rack, index) => (
              <Grid
                key={index}
                item
                xs={4}
                className="catalogo-racks-producto"

              > 

                  <div className="wrapper">
  <div className="product-card">
    <a href={`/Rack/${rack.id}`}  title='racks' className="product-link">
    {rack.imagen_sub_portada && (
                      <img
                        src={`${backendURL}/${rack.imagen_sub_portada}`}
                        alt={rack.alt_seo}
                        title={rack.alt_seo}
                        className="prodcutoracks"
                      />
                    )}
      <span className="overlay"></span>
      <span className="info">
        <span className="title">{rack.nombre}</span>
       
      </span>      
    </a>
    
    <div className="button-wrap">
      <a href={`/Rack/${rack.id}`} title='racks' className="cartbutton">VER MÁS</a>
      
    </div>

  </div>
</div>
                
              </Grid>
            ))}
          </Grid></Box> */}
               
            
           
     {/*  <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"100px"}}>
              <iframe
                className="video-cliente"
                src="https://stacker.cl/catalogo"
                title="grúas horquillas, racks, transpaletas, apiladores, pasillo angosto, Reach Stacker, order picker, alzahombres, transpaleta hombre a bordo, montacargas"
                
               
              ></iframe>
            </div>
                    </AccordionDetails>
                </Accordion>
            </Box>  */}
            

           
        </Container>   
            </>

    );
};